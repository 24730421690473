@charset 'utf-8';

// ==================== index ====================
// #1 layout
// #2 module
// #3 state
// #4 theme
// ==================== END index ====================

// ==================== #1 layout ====================
.l-wrapper
{
    width: 100%;
}

.l-container
{
    width: 100%;
}

.l-center
{
    margin: 0 auto;
}

.l-margin
{
    margin: 0 auto $gutter;
}

.l-margin-bottom-s
{
    margin-bottom: $gutter / 2;
}

.l-margin-bottom
{
    margin-bottom: $gutter;
}

.l-margin-bottom-m
{
    margin-bottom: $gutter-obj;
}

.l-margin-bottom-l
{
    margin-bottom: $gutter-sct;
}

.l-margin-bottom-b
{
    margin-bottom: $gutter-block;
}

.l-grid
{
    display: flex;
    align-content: stretch;
    flex-wrap: wrap;
    justify-content: space-around;
}

.l-item-list
{
    .grid-item
    {
        flex: 1 1;
        flex-basis: $grid-sp-2-1;
        margin: 0 $gutter / 2 $gutter / 2;
    }
}

.l-item-list-column
{
    flex-direction: column;
    flex-wrap: nowrap;
}

//mq min $mediaQuery-upper-very-small  - - - - - - - - - - - - - - - - - - - -
@media screen and (min-width: $mediaQuery-very-small)
{
    // 画面サイズが480pxからはここを読み込む
}

//mq min $mediaQuery-upper-very-small

//mq min $mediaQuery-upper-small  - - - - - - - - - - - - - - - - - - - -
@media screen and (min-width: $mediaQuery-small)
{
    // 画面サイズが768pxからはここを読み込む
}

//mq min $mediaQuery-upper-small

//mq min $mediaQuery-upper-middle  - - - - - - - - - - - - - - - - - - - -
@media screen and (min-width: $mediaQuery-middle)
{
    // 画面サイズが1000pxからはここを読み込む
    .l-main
    {
        width: $max-w;
        margin: 0 auto;
    }

    .l-feature-content,
    .l-pickup-content
    {
        display: flex;
    }

    .l-pickup-content
    {
        justify-content: space-between;

        padding: $gutter;
    }

    .l-margin-bottom
    {
        margin-bottom: $gutter-obj;
    }

    .l-grid
    {
        justify-content: flex-start;
    }

    .l-item-list
    {
        .grid-item
        {
            flex: 0 auto;

            flex-basis: $grid-pc-4-1;
            margin: 0 $gutter $gutter 0;

            &:nth-child(4n)
            {
                margin: 0 0 $gutter;
            }
        }
    }

    .l-item-list-column
    {
        flex-direction: column;
        flex-wrap: nowrap;
    }
}
//mq min $mediaQuery-upper-middle

//mq min $mediaQuery-upper-large  - - - - - - - - - - - - - - - - - - - -
@media screen and (min-width: $mediaQuery-large)
{
    // 画面サイズが1200pxからはここを読み込む
}

//mq min $mediaQuery-upper-large

// ==================== END #1 layout ====================

// ==================== #2 module ====================
.header,
.main,
.footer
{
    @include fts(16);
}

.hl3
{
    font-family: $ff-m;
    font-weight: $fw-b;

    @include fts(24, 32);
}

.section-title
{
    font-weight: $fw-b;

    display: block;

    width: 100%;
    padding: 0 $gutter / 2;

    text-align: center;

    &::after
    {
        display: block;

        width: 50%;
        margin: 0 auto;
        padding: $gutter 0 0;

        content: '';

        border-bottom: 1px solid $gray;
    }
}

.section-title-text
{
    font-weight: $fw-b;

    text-align: center;

    @include fts(12);
}

.img-container,
.banner-full,
.banner-list-item
{
    display: block;
}

a
{
    &.linkBtn
    {
        position: relative;

        width: 84%;
        margin: 0 auto;
        padding: $gutter / 2 $gutter-obj $gutter / 2 $gutter;

        color: $white;
        border-bottom: solid 4px $quinary-d1;
        border-radius: 4px;
        background-color: $quinary;

        @include fts(13, 16);

        &:active
        {
            -webkit-transform: translateY(4px);
                    transform: translateY(4px);

            border-bottom: solid 0 $quinary;
        }

        &::after
        {
            position: absolute;
            top: calc(50% - 10px);
            right: 10px;

            display: block;

            width: 20px;
            height: 20px;

            content: '';
            vertical-align: middle;

            background-image: url(../images/ui_arrow_right@3x.png);
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    &.linkBtn-sp
    {
        font-weight: $fw-b;

        text-align: center;

        @include fts(16);
    }

    &.list-link-item {
      &:active {
        opacity: .8;
      }
    }
} //a

.link-primary
{
    &::after
    {
        position: absolute;
        top: calc(50% - 6px);
        right: 10px;

        display: block;

        width: 12px;
        height: 12px;

        content: '';
        transform: rotate(45deg);

        border-top: solid 2px $black;
        border-right: solid 2px $black;
    }
}

.front {
  a
  {
      &.linkBtn
      {
        display: block;
          width: 90%;
          margin: 0 auto $gutter / 2;
          font-weight: $fw-b;
          border-bottom: 4px solid $quaternary-d1;
          background-color: $quaternary;
      }

  } //a
}

.page-mens {
  a
  {
      &.linkBtn
      {
          border-bottom: solid 4px $primary-d1;
          background-color: $primary;
      }

  } //a
}

.content-pc
{
    display: none;
}

.content-sp
{
    display: block;
}

@keyframes fadeIn {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}

//* # 2-1
//* -----------------------------
//* header
//* ------------------------------

.header
{
    position: relative;

    border-bottom: 3px solid $bc-primary;
}

//logo
.logo
{
    display: inline-block;

    margin: $gutter / 2;
}

.logo-img
{
    width: 75px;
}

// main-menu
.menu-button
{
    position: fixed;
    z-index: 99999;
    top: $gutter / 2;
    right: $gutter / 2;

    width: 46px;
    height: 46px;
    padding: $gutter / 2;

    background: transparent url(../images/menu_btn.png) center top no-repeat;
    background-size: cover;

    @include tr-all();
}

.main-menu
{
    position: fixed;
    z-index: 9999;
    top: 0;
    right: -100vw;

    visibility: hidden;
    overflow-y: auto;

    width: 65vw;
    height: 100vh;
    padding: 0 $gutter / 2 0;

    opacity: 0;
    background-color: $white;

    @include tr-all();
} // .main-menu

.menu-bg
{
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;

    visibility: hidden;

    width: 100vw;
    height: 100%;

    cursor: pointer;

    opacity: 0;
    background-color: $black;

    @include tr-all();
}

.nav-open
{
    .menu-bg
    {
        visibility: visible;

        opacity: .4;
    }

    .main-menu
    {
        right: 0;

        visibility: visible;

        opacity: 1;
    }

    .menu-button
    {
        right: calc(65vw + 8px);
        left: auto;
    }
}

//* # 2-2
//* -----------------------------
//* hero
//* ------------------------------
.hero
{
    height: 580px;
    padding: $gutter $gutter $gutter-sct;
}

.hero-text
{
    font-weight: $fw-b;

    position: relative;
    top: 300px;

    padding: $gutter / 4 $gutter + $gutter / 2;

    @include fts(16, 30);
}

.front
{
    .hero
    {
        background: url(../images/bg_hero_s.jpg) center top no-repeat;
        background-size: cover;
    }

    .hero-text
    {
        top: 266px;

        padding: $gutter / 4 $gutter / 2 + 5;

        @include fts(14,30);
    }
}

.page-uvcut
{
    .hero
    {
        background: url(../images/bg_hero_s_1.jpg) center top no-repeat;
        background-size: cover;
    }

    .hero-text
    {
        top: 178px;
    }
}

.page-cool
{
    .hero
    {
        background: url(../images/bg_hero_s_2.jpg) center top no-repeat;
        background-size: cover;
    }

    .hero-text
    {
        top: 242px;
    }
}

.page-rain
{
    .hero
    {
        background: url(../images/bg_hero_s_3.jpg) center top no-repeat;
        background-size: cover;
    }

    .hero-text
    {
        top: 232px;
    }
}

.page-chief-umbrella
{
    .hero
    {
        background: url(../images/bg_hero_s_4.jpg) center top no-repeat;
        background-size: cover;
    }
}

.page-folding-umbrella
{
    .hero
    {
        background: url(../images/bg_hero_s_5.jpg) center top no-repeat;
        background-size: cover;
    }

    .hero-text
    {
        top: 272px;

        padding: $gutter / 4;

        @include fts(14, 30);
    }
}

.page-small-size
{
    .hero
    {
        background: url(../images/bg_hero_s_6.jpg) center top no-repeat;
        background-size: cover;
    }

    .hero-text
    {
        top: 246px;

        padding: $gutter / 4 $gutter / 2;
    }
}

.page-large-size
{
    .hero
    {
        height: 552px;

        background: url(../images/bg_hero_s_7.jpg) center top no-repeat;
        background-size: cover;
    }

    .hero-text
    {
        top: 228px;

        padding: $gutter / 4 $gutter / 2;
    }
}

.page-weight
{
    .hero
    {
        background: url(../images/bg_hero_s_8.jpg) center top no-repeat;
        background-size: cover;
    }

    .hero-text
    {
        top: 272px;

        padding: $gutter / 4 $gutter / 2;
    }
}

.page-color
{
    .hero
    {
        background: url(../images/bg_hero_s_9.jpg) center top no-repeat;
        background-size: cover;
    }

    .hero-text
    {
        top: 226px;

        padding: $gutter / 4 $gutter / 2;
    }
}

.page-business
{
    .hero
    {
        background: url(../images/bg_hero_s_10.jpg) center top no-repeat;
        background-size: cover;
    }

    .hero-text
    {
        top: 286px;

        padding: $gutter / 4 $gutter / 2;

        @include fts(15,30);
    }
}

.page-casual
{
    .hero
    {
        background: url(../images/bg_hero_s_11.jpg) center -96px no-repeat;
        background-size: cover;
    }

    .hero-title {
      position: relative;
      top: 36px;
    }

    .hero-text
    {
        top: 318px;

        padding: $gutter / 4 $gutter / 2;

        @include fts(15,30);
    }
}

.page-ladies
{
    .hero
    {
        background: url(../images/bg_hero_s_12.jpg) center top no-repeat;
        background-size: cover;
    }

    .hero-title {
      position: relative;
      top: 16px;
    }

    .hero-text
    {
        top: 278px;

        padding: $gutter / 4 $gutter / 2;

        @include fts(15,30);
    }
}

.page-sport
{
    .hero
    {
        background: url(../images/bg_hero_s_13.jpg) center top no-repeat;
        background-size: cover;
    }

    .hero-title {
      position: relative;
      top: 16px;
    }

    .hero-text
    {
        top: 276px;

        @include fts(16 ,30);
    }
}

.page-wasou
{
    .hero
    {
        background: url(../images/bg_hero_s_14.jpg) center top no-repeat;
        background-size: cover;
    }

    .hero-text
    {
        top: 262px;
    }
}

.page-gift1
{
    .hero
    {
      height: 453px;
        background: url(../images/bg_hero_s_15.jpg) center top no-repeat;
        background-size: cover;
    }

    .hero-text
    {
        top: 160px;
        padding: $gutter / 4 $gutter / 2 + 5;
    }
}

.page-gift2
{
    .hero
    {
      height: 453px;
        background: url(../images/bg_hero_s_16.jpg) center top no-repeat;
        background-size: cover;
    }

    .hero-text
    {
        top: 136px;
        padding: $gutter / 4 $gutter / 2 + 5;
    }
}

.page-gift3
{
    .hero
    {
      height: 529px;
        background: url(../images/bg_hero_s_17.jpg) center top no-repeat;
        background-size: cover;
    }

    .hero-text
    {
        top: 240px;
        padding: $gutter / 4 $gutter / 2 + 5;

        @include fts(15,30);
    }
}

.page-mens
{
    .hero
    {
      height: 600px;
        background: url(../images/bg_hero_s_18.jpg) center top no-repeat;
        background-size: cover;
    }

    .hero-text
    {
        top: 288px;
        padding: $gutter / 4 $gutter / 2 + 5;
        color: $white;

        @include fts(14,30);
    }
}

//* # 2-3
//* -----------------------------
//* feature
//* ------------------------------
.feature
{
    padding: $gutter $gutter / 2;
    // 背景ストライプ

    background-image: linear-gradient(
    -45deg,
    $c-bg-secondary 25%,
    $c-bg-primary 25%,
    $c-bg-primary 50%,
    $c-bg-secondary 50%,
    $c-bg-secondary 75%,
    $c-bg-primary 75%,
    $c-bg-primary
    );
    background-size: $gutter / 4 $gutter / 4;
}

.feature-content-item
{
    width: $grid-sp-1-1;
    margin: 0 auto $gutter-obj;
    padding: $gutter - 5px 0 0;

    p
    {
        padding: 0 $gutter;

        @include fts(14, 24);
    }

    span
    {
        font-weight: $fw-b;

        color: $secondary;
    }

    .emphasis
    {
        color: $primary;
    }

    .content-title
    {
        margin: 0 $gutter 0 auto;
    }
} //.feature-content-item

.page-mens {
  .feature-content-item {
    span {
      color: $secondary-mens;
    }
  }
}

.content-title
{
    width: auto;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.feature-content-1
{
    background: url(../images/bg_feature_s_1_1.png) left top no-repeat;
    background-size: cover;

    .content-title
    {
        width: 42%;
    }
}

.feature-content-2
{
    margin-bottom: 0;

    background: url(../images/bg_feature_s_1_2.png) left top no-repeat;
    background-size: cover;

    .content-title
    {
        width: 60%;
    }
}

.front
{
  .feature {
    background-image: none;
  }
    .feature-content-item {
      height: auto;
        margin: 0 auto $gutter-obj;
        padding: $gutter - 5px 0 $gutter;
        background: $c-bg-quaternary url(../images/bg_qaa_s.png) center top no-repeat;
        background-size: 100%;
        border-radius: $default-border-radius / 2;
    }

    .feature-content-1
    {
        .content-title
        {
            width: 66%;
        }
    }

    .feature-content-2
    {
        .content-title
        {
            width: 60%;
        }
    }

    .feature-content-3
    {
        .content-title
        {
            width: 62%;
        }
    }
}

.page-cool
{
    .feature-content-1
    {
        background: url(../images/bg_feature_s_2_1.png) left top no-repeat;
        background-size: cover;

        .content-title
        {
            width: 50%;
        }
    }

    .feature-content-2
    {
        background: url(../images/bg_feature_s_2_2.png) left top no-repeat;
        background-size: cover;

        .content-title
        {
            width: 52%;
        }
    }
}

.page-rain
{
    .feature-content-1
    {
        background: url(../images/bg_feature_s_3_1.png) left top no-repeat;
        background-size: cover;

        .content-title
        {
            width: 50%;
        }
    }

    .feature-content-2
    {
        background: url(../images/bg_feature_s_3_2.png) left top no-repeat;
        background-size: cover;

        .content-title
        {
            width: 62%;
        }
    }
}

.page-chief-umbrella,
.page-folding-umbrella
{
    .feature-content-1
    {
        background: url(../images/bg_feature_s_4_1.png) left top no-repeat;
        background-size: cover;

        .content-title
        {
            width: 56%;
        }
    }

    .feature-content-2
    {
        background: url(../images/bg_feature_s_4_2.png) left top no-repeat;
        background-size: cover;

        .content-title
        {
            width: 60%;
        }
    }
}

.page-small-size,
.page-large-size
{
    .feature-content-1
    {
        background: url(../images/bg_feature_s_6_1.png) left top no-repeat;
        background-size: cover;

        .content-title
        {
            width: 60%;
        }
    }

    .feature-content-2
    {
        background: url(../images/bg_feature_s_6_2.png) left top no-repeat;
        background-size: cover;
    }
}

.page-weight
{
    .feature-content-1
    {
        height: 526px;

        background: url(../images/bg_feature_s_8_1.png) left top no-repeat;
        background-size: cover;

        .content-title
        {
            width: 54%;
            margin-bottom: $gutter-block;
        }
    }

    .feature-content-2
    {
        background: url(../images/bg_feature_s_8_2.png) left top no-repeat;
        background-size: cover;
    }
}

.page-color
{
    .feature-content-1
    {
        background: url(../images/bg_feature_s_9_1.png) left top no-repeat;
        background-size: cover;

        .content-title
        {
            width: 60%;
        }
    }

    .feature-content-2
    {
        background: url(../images/bg_feature_s_9_2.png) left top no-repeat;
        background-size: cover;
    }
}

.page-business
{
    .feature-content-1
    {
        background: url(../images/bg_feature_s_10_1.png) left top no-repeat;
        background-size: cover;

        .content-title
        {
            width: 62%;
        }
    }

    .feature-content-2
    {
        background: url(../images/bg_feature_s_10_2.png) left top no-repeat;
        background-size: cover;

        .content-title
        {
            width: 54%;
        }
    }
}

.page-casual
{
    .feature-content-1
    {
        height: 454px;
        background: url(../images/bg_feature_s_11_1.png) left top no-repeat;
        background-size: contain;

        .content-title
        {
            width: 60%;
            margin-bottom: $gutter-block;
        }
    }

    .feature-content-2
    {
        background: url(../images/bg_feature_s_11_2.png) left top no-repeat;
        background-size: cover;

        .content-title
        {
            width: 56%;
        }
    }
}

.page-ladies
{
    .feature-content-1
    {
        background: url(../images/bg_feature_s_12_1.png) left top no-repeat;
        background-size: cover;

        .content-title
        {
            width: 60%;
        }
    }

    .feature-content-2
    {
        background: url(../images/bg_feature_s_12_2.png) left top no-repeat;
        background-size: cover;
    }
}

.page-sport
{
    .feature-content-1
    {
        background: url(../images/bg_feature_s_13_1.png) left top no-repeat;
        background-size: cover;

        .content-title
        {
            width: 60%;
        }
    }

    .feature-content-2
    {
        background: url(../images/bg_feature_s_13_2.png) left top no-repeat;
        background-size: cover;
    }
}

.page-wasou
{
    .feature-content-1
    {
        background: url(../images/bg_feature_s_14_1.png) left top no-repeat;
        background-size: cover;

        .content-title
        {
            width: 60%;
        }
    }

    .feature-content-2
    {
        background: url(../images/bg_feature_s_14_2.png) left top no-repeat;
        background-size: cover;
    }
}

.page-gift1
{
    .feature-content-1
    {
        background: url(../images/bg_feature_s_15_1.png) left top no-repeat;
        background-size: cover;

        .content-title
        {
            width: 58%;
        }
    }

    .feature-content-2
    {
        background: url(../images/bg_feature_s_15_2.png) left top no-repeat;
        background-size: cover;
    }
}

.page-gift2
{
    .feature-content-1
    {
        background: url(../images/bg_feature_s_16_1.png) left top no-repeat;
        background-size: cover;

        .content-title
        {
            width: 52%;
        }
    }

    .feature-content-2
    {
        background: url(../images/bg_feature_s_16_2.png) left top no-repeat;
        background-size: cover;
    }
}

.page-gift3
{
    .feature-content-1
    {
        background: url(../images/bg_feature_s_17_1.png) left top no-repeat;
        background-size: cover;

        .content-title
        {
            width: 60%;
        }
    }

    .feature-content-2
    {
        background: url(../images/bg_feature_s_17_2.png) left top no-repeat;
        background-size: cover;
    }
}

.page-mens
{
  .feature {
    background-image: none;
    background-color: #e9f8ff;
    border: 2px solid #90cbe9;
  }
    .feature-content-3
{
    height: auto;
    padding-top: 0;

    background: none;
}
}

.feature-content-3
{
    height: 240px;
    margin: 0 auto;
    padding-top: $gutter-sct;

    background: url(../images/bg_feature_s_1_3.png) left top no-repeat;
    background-size: cover;
}

//* # 2-4
//* -----------------------------
//* pickup
//* ------------------------------
.pickup-1,
.pickup-2
{
    position: relative;

    padding-bottom: $gutter-obj;
}

.pickup-point-img
{
    display: none;
}

.pickup-colFirst,
.pickup-colSecond
{
    position: relative;
}

.pickup-title
{
    text-align: center;
}

.pickup-title-img
{
    width: 62.5%;
    margin: 0 auto;
}

.pickup-title-text
{
    margin-bottom: $gutter;
    padding: 0 $gutter / 2;

    text-align: center;

    @include fts(11, 18);
}

.pickup-photo,
.pickup-voice
{
    margin: 0 auto $gutter;
    padding: 0 20px;
}

.pickup-voice
{
    position: relative;

    display: flex;

    width: calc(100vw - 40px);
    padding: 28px $gutter / 2 $gutter / 2;

    border-radius: 4px;
    background-color: $white;

    &::after
    {
        position: absolute;
        top: -28px;
        left: 0;

        display: block;

        width: 50%;
        height: 48px;

        content: '';
        vertical-align: middle;

        background-image: url(../images/pickup_voice_title@3x.png);
        background-repeat: no-repeat;
        background-size: contain;
    }

    &::before
    {
        position: relative;
        top: 0;

        display: block;
        flex-basis: 60px;

        margin-right: 10px;

        content: '';
        vertical-align: middle;

        background-image: url(../images/pickup_voice_user@3x.png);
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.pickup-voice-text
{
    font-family: $ff-pop;

    width: 100%;

    @include fts(13);
}

//* # 2-5
//* -----------------------------
//* item-list
//* ------------------------------
a
{
    &.item-list-link
    {
        display: block;

        color: $a-link;
        border: none;
    }
}

.item-list-data
{
    font-weight: $fw-b;

    padding: $gutter / 2;

    color: $white;
    background-color: $quinary;

    @include fts(13);
}

//* # 2-6
//* -----------------------------
//* navigation
//* ------------------------------
.menu-list
{
    display: block;
    margin: 0;
    padding: 0 $gutter / 2;

    li
    {
        list-style: none;

        @include fts(13);
    }
}

a.menu-link
{
    position: relative;

    display: block;

    padding: $gutter / 4 $gutter $gutter / 4 0;

    border-bottom: 1px solid $gray;

    &.link-primary
    {
        &::after
        {
            right: 5px;

            border-color: $gray;
        }
    }
}

.menu-title
{
    position: relative;
    font-weight: $fw-b;

    margin: 0;
    padding: $gutter / 2 0;
    cursor: pointer;

    @include tr-all();

    &::after
    {
        position: absolute;
        top: 12px;
        right: $gutter / 4;

        display: block;

        width: 12px;
        height: 12px;

        content: '';
        transform: rotate(135deg);

        border-top: solid 2px $black;
        border-right: solid 2px $black;

        @include tr-all();
    }

    &.menu-title-top {
      &::after {
        display: none;
      }
    }

    &.open
    {
        color: $secondary;



        &::after
        {
            transform: rotate(-45deg);

            border-top: solid 2px $gray;
            border-right: solid 2px $gray;
        }
    }
}

.menu-item
{
    display: none;
    margin: 0;

    opacity: 0;

    @include tr-all();

    ul {
      padding: 0;
    }

    &.open
    {
        display: block;

        padding: 0 0 $gutter / 2;

        animation: fadeIn 1s forwards;

    }
}



.footer-menu-list {

  .menu-title {
    margin-bottom: $gutter / 2;
        @include fts(16,16);
  }

  .menu-item {
    padding: 10px 0;
  }
}

//* # 2-7
//* -----------------------------
//* footer
//* ------------------------------
.footer
{
    padding-bottom: $gutter;
}

.btn-pnav
{
    position: relative;

    display: block;

    padding: $gutter / 2 $gutter;

    background-color: $light-gray;

    &::after
    {
        position: absolute;
        top: calc(50% - 6px);
        right: $gutter;

        display: block;

        width: 12px;
        height: 12px;

        content: '';
        transform: rotate(-45deg);

        border-top: solid 2px $black;
        border-right: solid 2px $black;
    }
}

.copyright
{
    display: block;

    text-align: center;
}

//mq min $mediaQuery-upper-very-small  - - - - - - - - - - - - - - - - - - - -
@media screen and (min-width: $mediaQuery-very-small)
{
    // 画面サイズが480pxからはここを読み込む
}
//mq min $mediaQuery-upper-very-small

//mq min $mediaQuery-upper-small  - - - - - - - - - - - - - - - - - - - -
@media screen and (min-width: $mediaQuery-small)
{
    // 画面サイズが768pxからはここを読み込む
}

//mq min $mediaQuery-upper-small

//mq min $mediaQuery-upper-middle  - - - - - - - - - - - - - - - - - - - -
@media screen and (min-width: $mediaQuery-middle)
{
    // 画面サイズが1000pxからはここを読み込む
    .content-pc
    {
        display: block;
    }

    .content-sp
    {
        display: none;
    }



    .front {
      a
      {
          &.linkBtn
          {
              width: 80%;
              text-align: center;
              @include fts(18);
          }

      } //a
    }

    a{
      &.list-link-item {
        &:hover {
          opacity: .8;
        }
      }
    }

    //* # 2-1-upper-middle
    //* -----------------------------
    //* header
    //* ------------------------------
    .l-header
    {
        display: flex;
        flex-direction: column-reverse;

        border-bottom: 0;
    }

    .logo
    {
      width: 1000px;
      margin: $gutter / 2 auto;
      display: flex;
      align-items: center;
    }

    .header-subTitle {
      margin-left: $gutter / 2;
      @include fts(13);
      font-weight: $fw-b;
    }

    .logo-img
    {
        width: 134px;
    }

    .breadcrumb
    {
        padding: $gutter 0;

        @include fts(13, 13);
    }

    //* # 2-2-upper-middle
    //* -----------------------------
    //* hero
    //* ------------------------------
    .hero
    {
        height: 360px;
        padding: $gutter;
    }

    .hero-text
    {
        font-family: $ff-m;
        font-weight: $fw-b;

        top: 10px;

        width: 380px;
        padding: $gutter + 5 $gutter + $gutter / 2;

        @include fts(13, 30);
    }

    .hero-title
    {
        margin-bottom: 0;

        .content-title
        {
            margin: 0 0 0 $gutter / 2;
        }
    }

    .front
    {
        .hero
        {
            background: url(../images/bg_hero.jpg) center top no-repeat;
            background-size: contain;
        }

        .hero-text
        {
            top: 20px;
            padding: $gutter / 4 - 5px $gutter;
        }
    } //.front

    .page-uvcut
    {
        .hero
        {
            background: url(../images/bg_hero_1.jpg) center top no-repeat;
            background-size: contain;
        }

        .hero-text
        {
            top: 0;
        }
    } //.page-uvcut

    .page-cool
    {
        .hero
        {
            background: url(../images/bg_hero_2.jpg) center top no-repeat;
            background-size: contain;
        }

        .hero-text
        {
            top: 10px;
        }
    } //.page-cool

    .page-rain
    {
        .hero
        {
            background: url(../images/bg_hero_3.jpg) center top no-repeat;
            background-size: cover;
        }

        .hero-text
        {
            top: 10px;
        }
    } // .page-rain

    .page-chief-umbrella
    {
        .hero
        {
            background: url(../images/bg_hero_4.jpg) center top no-repeat;
            background-size: cover;
        }

        .hero-text
        {
            top: 5px;
        }
    } // .page-chief-umbrella

    .page-folding-umbrella
    {
        .hero
        {
            background: url(../images/bg_hero_5.jpg) center top no-repeat;
            background-size: cover;
        }

        .hero-text
        {
            top: 10px;

            padding: $gutter + 5 $gutter + $gutter / 2;

            @include fts(13, 30);
        }
    } // .page-folding-umbrella

    .page-small-size
    {
        .hero
        {
            background: url(../images/bg_hero_6.jpg) center top no-repeat;
            background-size: cover;
        }

        .hero-text
        {
            top: 10px;

            padding: $gutter + 5 $gutter + $gutter / 2;

            @include fts(13, 30);
        }
    } // .page-small-size

    .page-large-size
    {
        .hero
        {
            height: 360px;

            background: url(../images/bg_hero_7.jpg) center top no-repeat;
            background-size: contain;
        }

        .hero-text
        {
            top: 10px;

            padding: $gutter + 5 $gutter + $gutter / 2;

            @include fts(13, 30);
        }
    } // .page-large-size

    .page-weight
    {
        .hero
        {
            background: url(../images/bg_hero_8.jpg) center top no-repeat;
            background-size: contain;
        }

        .hero-text
        {
            top: 26px;
            padding: $gutter / 4 $gutter;
        }
    } // .page-weight

    .page-color
    {
        .hero
        {
            background: url(../images/bg_hero_9.jpg) center top no-repeat;
            background-size: contain;
        }

        .hero-text
        {
            top: 32px;
            padding: $gutter / 4 $gutter;
        }
    } // .page-color

    .page-business
    {
        .hero
        {
            background: url(../images/bg_hero_10.jpg) center top no-repeat;
            background-size: contain;
        }

        .hero-text
        {
            top: 96px;
            left: 56px;
            padding: $gutter / 4 $gutter;
        }
    } // .page-business

    .page-casual
    {
        .hero
        {
            background: url(../images/bg_hero_11.jpg) center top no-repeat;
            background-size: contain;
        }

        .hero-text
        {
            top: 96px;
            left: 26px;
            padding: $gutter / 4 $gutter;
            @include fts(14,30);
        }
    } // .page-casual

    .page-ladies
    {
        .hero
        {
            background: url(../images/bg_hero_12.jpg) center top no-repeat;
            background-size: contain;
        }

        .hero-text
        {
            top: 28px;
            left: 25px;
            padding: $gutter / 4 $gutter;
        }
    } // .page-ladies

    .page-sport
    {
        .hero
        {
            background: url(../images/bg_hero_13.jpg) center top no-repeat;
            background-size: contain;
        }

        .hero-title {
          top: 0;
          left: -24px;
        }

        .hero-text
        {
            top: 18px;
            padding: $gutter / 4 $gutter;
        }
    } // .page-sport

    .page-wasou
    {
        .hero
        {
            background: url(../images/bg_hero_14.jpg) center top no-repeat;
            background-size: contain;
        }

        .hero-title {
          top: -8px;
          left: -8px;
        }

        .hero-text
        {
            top: 10px;
            padding: $gutter / 4 $gutter;
            @include fts(14, 30);
        }
    } // .page-wasou

    .page-gift1
    {
        .hero
        {
            height: 360px;
            background: url(../images/bg_hero_15.jpg) center top no-repeat;
            background-size: contain;
        }

        .hero-text
        {
            top: 26px;
            padding: $gutter / 4 15px $gutter / 4 30px;
            @include fts(14, 30);
        }
    } // .page-gift1

    .page-gift2
    {
        .hero
        {
            height: 360px;
            background: url(../images/bg_hero_16.jpg) center top no-repeat;
            background-size: contain;
        }

        .hero-title {
          position: relative;
          top: 90px;
        }

        .hero-text
        {
            top: 108px;
            padding: $gutter / 4 15px $gutter / 4 30px;
            @include fts(14, 30);
        }
    } // .page-gift2

    .page-gift3
    {
        .hero
        {
            height: 360px;
            background: url(../images/bg_hero_17.jpg) center top no-repeat;
            background-size: contain;
        }

        .hero-text
        {
            top: 32px;
            padding: $gutter / 4 15px $gutter / 4 30px;
            @include fts(14, 30);
        }
    } // .page-gift3

    .page-mens
    {
        .hero
        {
            height: 360px;
            background: url(../images/bg_hero_18.jpg) center top no-repeat;
            background-size: contain;
        }

        .hero-title {
          position: relative;
          top: 60px;
          left: 30px;
        }

        .hero-text
        {
            top: 80px;
            width: 420px;
            padding: 0;

            font-family: $ff;
            @include fts(14, 30);
        }
    } // .page-mens


    //* # 2-3-upper-middle
    //* -----------------------------
    //* feature
    //* ------------------------------
    .feature
    {
        margin-bottom: $gutter-sct;
    }

    .feature-content-item
    {
        width: $grid-pc-2-1;
        height: 333px;
        margin: 0 auto;
        padding: 0;

        .img-container
        {
            position: relative;

            padding-bottom: 70px;
        }

        .content-title
        {
            position: absolute;
            top: 25px;
            right: $gutter;

            width: auto;
            max-width: 100%;
            margin: 0;
        }

        p
        {
            padding: 0 $gutter;

            @include fts(14, 22);
        }
    } //.feature-content-item

    .feature-content-1
    {
        background: url(../images/bg_feature_1_1.png) center top no-repeat;
        background-size: cover;

        p
        {
            width: 390px;
        }
    }

    .feature-content-2
    {
        margin-bottom: 0;

        background: url(../images/bg_feature_1_2.png) center top no-repeat;
        background-size: cover;
    }

    .feature-content-3
    {
        width: 960px;
        height: 111px;
        padding-top: 50px;

        background: url(../images/bg_feature_1_3.png) center top no-repeat;
        background-size: cover;
    }

    .front {
      .l-feature-content {
        flex-direction: column;
      }

      .feature-content-item {
        width: 100%;
        background: $c-bg-quaternary url(../images/bg_qaa.png) center top no-repeat;
        background-size: 100%;

        .img-container {
          padding: 0;
        }

        .content-title {
          top: -7px;
          right: auto;
          left: $gutter;
        }

        p {
          width: 100%;
          padding: $gutter;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .page-cool
    {
        .feature-content-1
        {
            background: url(../images/bg_feature_2_1.png) center top no-repeat;
            background-size: cover;

            .content-title
            {
                width: 70%;
            }
        }

        .feature-content-2
        {
            background: url(../images/bg_feature_2_2.png) center top no-repeat;
            background-size: cover;

            .content-title
            {
                width: 65%;
            }
        }
    } // .page-cool

    .page-rain
    {
        .feature-content-1
        {
            background: url(../images/bg_feature_3_1.png) center top no-repeat;
            background-size: cover;

            .content-title
            {
                width: 70%;
            }
        }

        .feature-content-2
        {
            background: url(../images/bg_feature_3_2.png) center top no-repeat;
            background-size: cover;

            .content-title
            {
                width: 70%;
            }
        }
    } // page-rain

    .page-chief-umbrella,
    .page-folding-umbrella
    {
        .feature-content-1
        {
            background: url(../images/bg_feature_4_1.png) center top no-repeat;
            background-size: cover;

            .content-title
            {
                width: 70%;
            }
        }

        .feature-content-2
        {
            background: url(../images/bg_feature_4_2.png) center top no-repeat;
            background-size: cover;

            .content-title
            {
                width: 70%;
            }

            p
            {
                width: 420px;
            }
        }
    } // page-chief-umbrella

    .page-small-size,
    .page-large-size
    {
        .feature-content-1
        {
            background: url(../images/bg_feature_6_1.png) center top no-repeat;
            background-size: cover;

            .content-title
            {
                width: 70%;
            }

            p
            {
                width: 360px;
            }
        }

        .feature-content-2
        {
            background: url(../images/bg_feature_6_2.png) center top no-repeat;
            background-size: cover;

            .content-title
            {
                width: 70%;
            }

            p
            {
                width: 360px;
            }
        }
    } // page-small-size

    .page-weight
    {
        .feature-content-1
        {
            height: 333px;
            background: url(../images/bg_feature_8_1.png) center top no-repeat;
            background-size: cover;

            .content-title
            {
                width: 70%;
            }

            p
            {
                width: 370px;
            }
        }

        .feature-content-2
        {
            background: url(../images/bg_feature_8_2.png) center top no-repeat;
            background-size: cover;

            .content-title
            {
                width: 70%;
            }
        }
    } // page-weight

    .page-color
    {
      .feature-content-1,
      .feature-content-2 {


            .content-title
            {
                width: 70%;
            }

            p
            {
                width: 370px;
            }
      }

        .feature-content-1
        {
            height: 333px;
            background: url(../images/bg_feature_9_1.png) center top no-repeat;
            background-size: cover;
        }

        .feature-content-2
        {
            background: url(../images/bg_feature_9_2.png) center top no-repeat;
            background-size: cover;
        }
    } // page-color

    .page-business
    {
      .feature-content-1,
      .feature-content-2 {

            .content-title
            {
                width: 70%;
            }

            p
            {
                width: 370px;
            }
      }

        .feature-content-1
        {
            height: 333px;
            background: url(../images/bg_feature_10_1.png) center top no-repeat;
            background-size: cover;
        }

        .feature-content-2
        {
            background: url(../images/bg_feature_10_2.png) center top no-repeat;
            background-size: cover;
        }
    } // page-business

    .page-casual
    {
      .feature-content-1,
      .feature-content-2 {

            .content-title
            {
                width: 70%;
            }

            p
            {
                width: 370px;
            }
      }
        .feature-content-1
        {
            height: 333px;
            background: url(../images/bg_feature_11_1.png) center top no-repeat;
            background-size: cover;
        }

        .feature-content-2
        {
            background: url(../images/bg_feature_11_2.png) center top no-repeat;
            background-size: cover;
        }
    } // page-casual

    .page-ladies
    {
      .feature-content-1,
      .feature-content-2 {


            .content-title
            {
                width: 70%;
            }

            p
            {
                width: 370px;
            }
      }

        .feature-content-1
        {
            height: 333px;
            background: url(../images/bg_feature_12_1.png) center top no-repeat;
            background-size: cover;
        }

        .feature-content-2
        {
            background: url(../images/bg_feature_12_2.png) center top no-repeat;
            background-size: cover;
        }
    } // page-ladies

    .page-sport
    {
      .feature-content-1,
      .feature-content-2 {
            .content-title
            {
                width: 70%;
            }

            p
            {
                @include fts(13);
            }
      }

        .feature-content-1
        {
            height: 333px;
            background: url(../images/bg_feature_13_1.png) center top no-repeat;
            background-size: cover;

            p
            {
                width: auto;
            }
        }

        .feature-content-2
        {
            background: url(../images/bg_feature_13_2.png) center top no-repeat;
            background-size: cover;

            p
            {
                width: 370px;
            }
        }
    } // page-sport

    .page-wasou
    {
      .feature-content-1,
      .feature-content-2 {

            p
            {
                @include fts(13);
            }

      }

        .feature-content-1
        {
            height: 333px;
            background: url(../images/bg_feature_14_1.png) center top no-repeat;
            background-size: cover;
            .content-title
            {
                width: 70%;
            }

            p
            {
                width: auto;
            }
        }

        .feature-content-2
        {
            background: url(../images/bg_feature_14_2.png) center top no-repeat;
            background-size: cover;
            .content-title
            {
                width: 68%;
            }

            p
            {
                width: 370px;
            }
        }
    } // page-wasou

    .page-gift1
    {

        .feature-content-1
        {
            height: 333px;
            background: url(../images/bg_feature_15_1.png) center top no-repeat;
            background-size: cover;
            .content-title
            {
                width: 70%;
            }

            p
            {
                width: auto;
            }
        }

        .feature-content-2
        {
            background: url(../images/bg_feature_15_2.png) center top no-repeat;
            background-size: cover;
            .content-title
            {
                width: 68%;
            }

            p
            {
                width: 370px;
            }
        }
    } // page-gift1

    .page-gift2
    {

        .feature-content-1
        {
            height: 333px;
            background: url(../images/bg_feature_16_1.png) center top no-repeat;
            background-size: cover;
            .content-title
            {
                width: 70%;
            }

            p
            {
                width: auto;
            }
        }

        .feature-content-2
        {
            background: url(../images/bg_feature_16_2.png) center top no-repeat;
            background-size: cover;
            .content-title
            {
                width: 68%;
            }

            p
            {
                width: 370px;
            }
        }
    } // page-gift2

    .page-gift3
    {

        .feature-content-1
        {
            height: 333px;
            background: url(../images/bg_feature_17_1.png) center top no-repeat;
            background-size: cover;
            .content-title
            {
                width: 70%;
            }

            p
            {
                width: auto;
            }
        }

        .feature-content-2
        {
            background: url(../images/bg_feature_17_2.png) center top no-repeat;
            background-size: cover;
            .content-title
            {
                width: 70%;
            }
        }
    } // page-gift3

    .page-mens
    {

        .feature-content-3
        {
            background: url(../images/bg_feature_18_1.png) right center no-repeat;
            background-size: contain;
            .content-title
            {
                width: 70%;
            }

            p
            {
                width: 660px;
            }
        }
    } // page-mens



    //* # 2-4-upper-middle
    //* -----------------------------
    //* pickup
    //* ------------------------------
    .pickup
    {
        margin-bottom: $gutter-sct;
    }

    .pickup-1,
    .pickup-2
    {
        padding: $gutter;
    }

    .pickup-colFirst,
    .pickup-colSecond
    {
        margin: 0;
    }
    .pickup-colFirst
    {
        width: 440px;
    }

    .pickup-colSecond
    {
        width: 500px;
    }

    .pickup-photo
    {
        padding: 0;
    }

    .pickup-voice
    {
        width: 100%;
    }

    a
    {
        &.linkBtn-pc
        {
            font-weight: $fw-b;

            padding-right: $gutter-obj;

            text-align: right;

            @include fts(12, 24);

            &:hover {
              background-color: $quinary-l1;
              border-color: $quinary;
            }
        }
    }

    //* # 2-5-upper-middle
    //* -----------------------------
    //* item-list
    //* ------------------------------
    .itemList
    {
        margin-bottom: $gutter-sct;
    }

    .item-list-capt
    {
        font-weight: $fw-b;

        overflow: hidden;

        height: 9rem;
        padding: $gutter / 2 0;

        @include fts(13);
    }

    .item-list-type
    {
        display: table;

        padding: $gutter / 4;

        color: $black;
        border-radius: 2px;
        background-color: $white;

        @include fts(11, 11);
    }

    //* # 2-6-upper-middle
    //* -----------------------------
    //* navigation
    //* ------------------------------
    .main-menu
    {
        position: relative;
        z-index: 9999;
        top: 0;
        right: auto;
        left: 0;

        visibility: visible;
        overflow-y: visible;

        width: 100%;
        height: auto;
        padding: 0 10px 0;

        opacity: 1;
        background-color: $black;
    }

    .main-menu-list
    {
        &.menu-list
        {
            display: flex;
            justify-content: space-between;

            width: $max-w;
            margin: 0 auto;

            color: $white;
        }

        a
        {
            &.menu-title-link,
            &.menu-link
            {
                font-weight: $fw-b;

                color: $white;
            }

            &.menu-link
            {
                padding: $gutter / 2;

                border: none;

                &:hover
                {
                    background-color: $quinary-d1;
                }

                &::after
                {
                    display: none;
                }
            }
        } // a

        .menu-title {
          position: relative;
          @include tr-all();

          &::after {
            display: none;
          }

          &:hover {
            color: $secondary;
          }
        }

        .menu-item
        {
            &.open
            {
                display: inline-block;
                overflow: hidden;
                position: absolute;
                top: 4.3rem;
                left: calc(50% - 160px);
                width: 320px;

                padding: 0;

                background-color: $quinary;
            }
        }
    } //.main-menu-list

    .footer-menu
    {
        margin-bottom: $gutter-sct;

        a
        {
            &.menu-link
            {
                margin-bottom: $gutter / 2;
                padding-bottom: $gutter / 2;

                @include fts(16, 16);

                font-weight: normal;

                &:hover
                {
                    border-bottom-color: $black;
                }
            }
        }
    }

    .footer-menu-list {

      &.menu-list {
        padding: 0;
      }

      .menu-title {
        margin-bottom: $gutter;
      }

      .menu-item {
        padding: 10px 0;
      }
    }

    //* # 2-7-upper-middle
    //* -----------------------------
    //* footer
    //* ------------------------------
    .footer
    {
        a
        {
            &.btn-pnav
            {
                width: $max-w;
                margin: 0 auto $gutter-obj;

                border-radius: 4px;
                background-color: $white;

                &:hover
                {
                    background-color: $light-gray;
                }
            }
        }
    }

    .footer-banner,
    .banner-list
    {
        margin-bottom: $gutter-sct;
    }

    .banner-list
    {
        .l-item-list-column
        {
            flex-direction: row;
            flex-wrap: wrap;
        }

        .grid-item
        {
            margin-right: 60px;

            &:nth-child(2n)
            {
                margin-right: 0;
            }
        }
    }
}
//mq min $mediaQuery-upper-middle

//mq min $mediaQuery-upper-large  - - - - - - - - - - - - - - - - - - - -
@media screen and (min-width: $mediaQuery-large)
{
    // 画面サイズが1200pxからはここを読み込む
}

//mq min $mediaQuery-upper-large

// ==================== END #2 module ====================

// ==================== #3 state ====================

//mq min $mediaQuery-upper-very-small  - - - - - - - - - - - - - - - - - - - -
@media screen and (min-width: $mediaQuery-very-small)
{
    // 画面サイズが480pxからはここを読み込む
}

//mq min $mediaQuery-upper-very-small

//mq min $mediaQuery-upper-small  - - - - - - - - - - - - - - - - - - - -
@media screen and (min-width: $mediaQuery-small)
{
    // 画面サイズが768pxからはここを読み込む
}

//mq min $mediaQuery-upper-small

//mq min $mediaQuery-upper-middle  - - - - - - - - - - - - - - - - - - - -
@media screen and (min-width: $mediaQuery-middle)
{
    // 画面サイズが1000pxからはここを読み込む
}

//mq min $mediaQuery-upper-middle

//mq min $mediaQuery-upper-large  - - - - - - - - - - - - - - - - - - - -
@media screen and (min-width: $mediaQuery-large)
{
    // 画面サイズが1200pxからはここを読み込む
}

//mq min $mediaQuery-upper-large

// ==================== END #3 state ====================

// ==================== #4 theme ====================

.theme-pickup-content
{
    background-image: linear-gradient(
    -45deg,
    $c-bg-quaternary 25%,
    $c-bg-tertiary 25%,
    $c-bg-tertiary 50%,
    $c-bg-quaternary 50%,
    $c-bg-quaternary 75%,
    $c-bg-tertiary 75%,
    $c-bg-tertiary
    );
    background-size: $gutter / 4 $gutter / 4;
}

//mq min $mediaQuery-upper-very-small  - - - - - - - - - - - - - - - - - - - -
@media screen and (min-width: $mediaQuery-very-small)
{
    // 画面サイズが480pxからはここを読み込む
}

//mq min $mediaQuery-upper-very-small

//mq min $mediaQuery-upper-small  - - - - - - - - - - - - - - - - - - - -
@media screen and (min-width: $mediaQuery-small)
{
    // 画面サイズが768pxからはここを読み込む
}

//mq min $mediaQuery-upper-small

//mq min $mediaQuery-upper-middle  - - - - - - - - - - - - - - - - - - - -
@media screen and (min-width: $mediaQuery-middle)
{
    // 画面サイズが1000pxからはここを読み込む
}
//mq min $mediaQuery-upper-middle

//mq min $mediaQuery-upper-large  - - - - - - - - - - - - - - - - - - - -
@media screen and (min-width: $mediaQuery-large)
{
    // 画面サイズが1200pxからはここを読み込む
}

//mq min $mediaQuery-upper-large

// ==================== END #4 theme ====================
