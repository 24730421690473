@charset "utf-8";

// *******************
// clearfix
%cf {
  *zoom: 1;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

// *******************
// vertical rhythm
@mixin fts($fts, $lin: 24) {
  $rem: $fts / 10;
  $linS: $lin / $fts;
  font-size: #{$fts}px;
  font-size: #{$rem}rem;
  line-height: $linS;
}

@mixin fts-body {
  @include fts(18, 28);
}

// *******************
// フレキシブルレイアウト
@mixin flex {
  -js-display: flex;
  display: -webkit-flex;
  display: flex;
}

@mixin flex-between {
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

@mixin flex-around {
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

@mixin flex-wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

@mixin flex-row {
  -webkit-flex-direction: row;
  flex-direction: row;
}

@mixin flex-column {
  -webkit-flex-direction: column;
  flex-direction: column;
}

@mixin flex-jc-center {
  -webkit-justify-content: center;
  justify-content: center;
}

@mixin flex-jc-start {
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

@mixin flex-jc-end {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

@mixin flex-ai-center {
  -webkit-align-items: center;
  align-items: center;
}

@mixin flex-ac-start {
  -webkit-align-content: flex-start;
  align-content: flex-start;
}

// *******************
// ボックス
@mixin inline-block {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline
}

// *******************
// 画像置換
@mixin img-replace {
  display: block;
  text-indent: 200%;
  white-space: nowrap;
  overflow: hidden;
}

// *******************
// トランジション all
@mixin transition {
  transition: ease-in .4s;
}

// リンクトランジション
@mixin tr-all($duration:.3s, $function:ease-out) {
  transition: all $duration $function;
}

// ロールオーバー透過制御のみ
@mixin tr-opacity($duration:.4s, $function:ease-out) {
  transition: opacity $duration $function;
}

// ロールオーバー基本-背景画像変更
%ro-basic-ro {
  @include tr-all(0, ease-in);
}

// ロールオーバー基本-透明度
@mixin ro-alpha() {
  @include tr-opacity(0.4s, ease-in);

  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);

  opacity: 1;
}

@mixin ro-alpha-ro() {
  @include tr-opacity(0.1s, ease-out);

  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);

  opacity: .5;
}

// *******************
// グレースケールグラデーション
@mixin bg-grd-gs-bt {
  background-image: -webkit-gradient(linear,
    left bottom,
    left top,
    color-stop(0.1, #FAFAFA),
    color-stop(1, #fff));
  background-image: -o-linear-gradient(top, #FAFAFA 10%, #fff 100%);
  background-image: -moz-linear-gradient(top, #FAFAFA 10%, #fff 100%);
  background-image: -webkit-linear-gradient(top, #FAFAFA 10%, #fff 100%);
  background-image: -ms-linear-gradient(top, #FAFAFA 10%, #fff 100%);
  background-image: linear-gradient(to top, #FAFAFA 10%, #fff 100%);
}

// ホワイトグラデーション
@mixin bg-grd-white {
  background: linear-gradient(90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, .4));
}

// 汎用グラデーション
@mixin bg-gradient($angle: 180deg, $color: #0bd, $amount: 20%) {
  background: linear-gradient($angle, $color, adjust-hue($color, $amount)) fixed;
}

// テキストグラデーション
@mixin gradient-text($angle: 45deg, $color: #f82, $amount: 35%) {
  color: $color;
  background: -webkit-linear-gradient($angle, $color, adjust-hue($color, $amount));
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  display: inline-block;
}

// *******************
// シャドウ
@mixin shadow-bottom {
  box-shadow: 0 2px 8px rgba(222, 223, 224, .4);
}

@mixin shadow-bottom-hover {
  box-shadow: 0 8px 16px rgba(222, 223, 224, .8);
}

@mixin shadow-btn {
  @include shadow-bottom;
}

@mixin shadow-btn-hover {
  @include shadow-bottom-hover;
}

@mixin shadow-txt {
  @include text-shadow(0px 2px 0 $gray-a4);
}

// *******************
// Google Map レスポンシブ

@mixin gmap-r {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}

// *******************
// ボーダー
@mixin border-radius($top-left:$default-border-radius, $top-right:null, $bottom-right:null, $bottom-left:null){
  -webkit-border-radius: $top-left $top-right $bottom-right $bottom-left;
     -moz-border-radius: $top-left $top-right $bottom-right $bottom-left;
      -ms-border-radius: $top-left $top-right $bottom-right $bottom-left;
          border-radius: $top-left $top-right $bottom-right $bottom-left;
}


// *******************
// ローディング

@mixin loading {

  // animation setting
  @-webkit-keyframes loading {
    0% {
      width: 0;
    }

    100% {
      width: 100%;
      -webkit-transform: translate(100%);
      transform: translate(100%);
    }
  }

  @keyframes loading {
    0% {
      width: 0;
    }

    100% {
      width: 100%;
      -webkit-transform: translate(100%);
      transform: translate(100%);
    }
  }

  @-webkit-keyframes loadingText {
    0% {
      opacity: 1;
      color: $primary;
    }

    50% {
      color: $primary-l2;

    }

    100% {
      opacity: .5;
      color: $white;
    }
  }

  @keyframes loadingText {
    0% {
      opacity: 1;
      color: $primary;
    }

    50% {
      color: $primary-l2;
    }

    100% {
      opacity: .5;
      color: $white;
    }
  }

  // loading effects
  .loading {
    position: fixed;
    z-index: 1100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .loading-line {
    line-height: 1;
    position: fixed;
    z-index: 1100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: inline-block;
    overflow: hidden;
    width: 10vh;
    height: 1rem;
    margin: auto;
    transition: all .1s cubic-bezier(.785, .135, .15, .86) 0s;
    transition-delay: .6s;
    text-align: center;
    @include border-radius(16px);
  }

  .loading-line:after {
    position: absolute;
    z-index: 1100;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 0;
    height: 100%;
    margin: auto auto auto 0;
    content: ' ';
    -webkit-animation: loading 2s ease-in-out .4s infinite normal backwards;
    animation: loading 2s ease-in-out .4s infinite normal backwards;
    background-color: $gray;
    @include border-radius(16px);
    will-change: transform, width;
  }

  .loading-line:before {
    font-family: $ff-en;
    font-size: 1.25em;
    font-weight: $fw-b;
    font-style: normal;
    line-height: 1;
    display: block;
    content: '';
    -webkit-animation: loadingText 1s ease .6s infinite alternate both;
    animation: loadingText 1s ease .6s infinite alternate both;
    letter-spacing: .5em;
    color: $gray;
    will-change: opacity;
  }

  .loaded .loading-line {
    overflow: hidden;
    height: 0;
  }

  .loaded .loading-line:after,
  .loaded .loading-line:before {
    -webkit-animation: none;
    animation: none;
  }

  .loaded .loading-line:before {
    content: '';
  }

  .loading:before,
  .loading:after {
    line-height: 1;
    position: fixed;
    z-index: 1099;
    right: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 50%;
    margin-right: auto;
    margin-left: auto;
    content: ' ';
    transition: all .8s cubic-bezier(.785, .135, .15, .86) 0s;
    transition-delay: .3s;
    background-color: $white;

  }

  .loading:before {
    top: 0;
  }

  .loading:after {
    bottom: 0;
  }

  .loaded .loading:before {
    height: 0;
    opacity: 0;
    -webkit-transform: translateY(-1%);
    -ms-transform: translateY(-1%);
    transform: translateY(-1%);
  }

  .loaded .loading:after {
    height: 0;
    -webkit-transform: translateY(1%);
    -ms-transform: translateY(1%);
    transform: translateY(1%);
  }
}

//mixin loading