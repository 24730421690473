@charset "utf-8";

*,
*:before,
*:after {
  box-sizing: border-box;

  @include tr-all(.6s,ease-in);
}

html,
body {
  height: 100%;
}

html {
  font-size: $fs-base;
}

header,
body,
footer {
  display: block;
}

body {
  margin: 0;
  color: $fc-primary;
  font-family: $ff;
  @include fts(16);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,h2,h3,h4,h5,h6,
p {
  margin: 0;
  padding: 0;
}

a,
a:focus,
a:link,
a:visited,
a:hover,
a:active,
img,
button, html [type="button"], [type="reset"], [type="submit"] {
  border: none;
  outline: none;
  -webkit-appearance: none;
}

img {
  display: block;
}

a,
a:link {
  color: $a-link;
  text-decoration: none;
  @include tr-all;
}

a:hover {
  color: $a-hover;
}

a:active {
  color: $a-active;
}

a:focus {
  outline: none;
}

h1 a {
  display: inline-block;
}

address, dl, fieldset, figure, ol, p, pre, ul {
  margin: 0;
}

*+address, *+dl, *+fieldset, *+figure, *+ol, *+p, *+pre, *+ul {
  margin-top: 0;
}

ol, ul {
  padding-left: $gutter;
}

strong {
  color: $quaternary;
}