@charset 'utf-8';

@import 'https://fonts.googleapis.com/css?family=Poppins:400,500,700';

// # 1
//* -----------------------------
//* Setting
//* ------------------------------

// # 2
//* -----------------------------
//* Valiables
//* ------------------------------

// Font

$ff: 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif;
$ff-m: 'Hiragino Mincho ProN', 'Yu Mincho', serif;
$ff-en: 'Poppins', $ff;
$ff-pop: 'M PLUS Rounded 1c', sans-serif;

$ff-sp: sans-serif;
$ff-m-sp: serif;

$fw-b: 600; //font-weight: bold;

$fs-base: 62.5%;
$fs-32: 3.2rem; //32px
$fs-28: 2.8rem; //28px
$fs-24: 2.4rem; //24px sp h2
$fs-22: 2.2rem; //22px
$fs-20: 2rem; //20px sp h3
$fs-18: 1.8rem; //18px
$fs-16: 1.6rem; //16px sp default
$fs-14: 1.4rem; //14px
$fs-13: 1.3rem; //13px default
$fs-12: 1.2rem; //12px
$fs-11: 1.1rem; //11px
$fs-10: 1rem; //10px
$fs-8: .8rem; //8px

// vertical-rhythm
$base-font-size: 16px;
$base-line-height: 24px;

$line-height: 1.65;
$line-height-min: 1.3;

// variable-colors
$transparent: rgba(255, 255, 255, 0);
$black: rgba(45, 45, 46, 1);
$b-a6: rgba(45, 45, 46, .6);
$b-a4: rgba(45, 45, 46, .4);
$b-a1: rgba(45, 45, 46, .1);
$white: rgba(255, 255, 255, 1);
$w-a9: rgba(255, 255, 255, .9);
$w-a8: rgba(255, 255, 255, .8);
$w-a6: rgba(255, 255, 255, .6);
$w-a4: rgba(255, 255, 255, .4);
$gray: rgba(222, 223, 224, 1);
$thin-gray: rgba(252, 253, 255, 1);
$light-gray: rgba(247, 248, 250, 1);
$dark-gray: rgba(56, 56, 56, 1);

$primary: #00a2c5; //theme
$primary-d1: darken($primary, 10);
$primary-d2: darken($primary, 20);
$primary-d3: darken($primary, 30);
$primary-l1: lighten($primary, 10);
$primary-l2: lighten($primary, 20);
$primary-l3: lighten($primary, 30);
$primary-a8: rgba(0, 160, 196, .8);
$primary-a6: rgba(0, 160, 196, .6);
$primary-a4: rgba(0, 160, 196, .4);
$primary-a2: rgba(0, 160, 196, .2);

$secondary: rgba(234, 75, 97, 1);
$secondary-d1: darken($secondary, 10);
$secondary-d2: darken($secondary, 20);
$secondary-d3: darken($secondary, 30);
$secondary-l1: lighten($secondary, 10);
$secondary-l2: lighten($secondary, 20);
$secondary-l3: lighten($secondary, 30);

$secondary-mens: #881024;

$tertiary: #fef1d4;
$tertiary-d1: darken($tertiary, 10);
$tertiary-d2: darken($tertiary, 20);
$tertiary-d3: darken($tertiary, 30);
$tertiary-l1: lighten($tertiary, 10);
$tertiary-l2: lighten($tertiary, 20);
$tertiary-l3: lighten($tertiary, 30);

$quaternary: #675747;
$quaternary-d1: darken($quaternary, 10);
$quaternary-d2: darken($quaternary, 20);
$quaternary-d3: darken($quaternary, 30);
$quaternary-l1: lighten($quaternary, 10);
$quaternary-l2: lighten($quaternary, 20);
$quaternary-l3: lighten($quaternary, 30);

$quinary: #ea8c97; //warning
$quinary-d1: #bf737c;
$quinary-d2: #8c545b;
$quinary-d3: darken($quinary, 30);
$quinary-l1: lighten($quinary, 10);
$quinary-l2: lighten($quinary, 20);
$quinary-l3: lighten($quinary, 30);

$c-bg-primary: #daccae;
$c-bg-secondary: #ded0b6;

$c-bg-tertiary: #f6eee2;
$c-bg-quaternary: #faf5ee;

// sub
$bc-primary: #c1ab95;

// text color
$fc-primary: $black;

// link
$a-link: $black;
$a-visited: $black;
$a-hover: $dark-gray;
$a-active: $black;

// variable-box
$box-size: 375px;

$gutter: 20px;
$gutter-obj: $gutter * 2;
$gutter-sct: $gutter * 4;
$gutter-block: $gutter * 8;

$margin: 0 auto $gutter-obj auto;

$border: 1px solid $light-gray;

$max-w: 1000px;
$max-w-s: $box-size;

$card-w-s: 44vw;
$card-w-m: round($max-w / 2) - $gutter-obj;

// navigation menu
$gnav: $max-w;

// image size
$thumb-pc-w: 328px;
$thumb-sp-w: $card-w-s;
$thumb-sp-h: 96px;

// default value
$default-border-radius: 8px;

//mediaQueryの基準となる幅
$mediaQuery-large: 1280px + 1px;
$mediaQuery-middle: $max-w;
$mediaQuery-small: 768px + 1px;
$mediaQuery-very-small: 480px + 1px;
$mediaQuery-mini: 320px + 1px;

// grid
$grid-sp-1-1: 100%;
$grid-sp-2-1: 44%;

$grid-pc-1-1: $mediaQuery-middle;
$grid-pc-2-1: $mediaQuery-middle / 2 - ($gutter + $gutter / 2);
$grid-pc-4-1: 235px;

// pc nav
$menu-item-min-width: 150px;
$menu-item-primary: $black;
$menu-item-secondary: $secondary;

