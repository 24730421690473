@import 'https://fonts.googleapis.com/css?family=Poppins:400,500,700';
*,
*:before,
*:after {
  box-sizing: border-box;
  transition: all 0.6s ease-in;
}

html,
body {
  height: 100%;
}

html {
  font-size: 62.5%;
}

header,
body,
footer {
  display: block;
}

body {
  margin: 0;
  color: #2d2d2e;
  font-family: "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6,
p {
  margin: 0;
  padding: 0;
}

a,
a:focus,
a:link,
a:visited,
a:hover,
a:active,
img,
button, html [type="button"], [type="reset"], [type="submit"] {
  border: none;
  outline: none;
  -webkit-appearance: none;
}

img {
  display: block;
}

a,
a:link {
  color: #2d2d2e;
  text-decoration: none;
  transition: all 0.3s ease-out;
}

a:hover {
  color: #383838;
}

a:active {
  color: #2d2d2e;
}

a:focus {
  outline: none;
}

h1 a {
  display: inline-block;
}

address, dl, fieldset, figure, ol, p, pre, ul {
  margin: 0;
}

* + address, * + dl, * + fieldset, * + figure, * + ol, * + p, * + pre, * + ul {
  margin-top: 0;
}

ol, ul {
  padding-left: 20px;
}

strong {
  color: #675747;
}

.l-wrapper {
  width: 100%;
}

.l-container {
  width: 100%;
}

.l-center {
  margin: 0 auto;
}

.l-margin {
  margin: 0 auto 20px;
}

.l-margin-bottom-s {
  margin-bottom: 10px;
}

.l-margin-bottom {
  margin-bottom: 20px;
}

.l-margin-bottom-m {
  margin-bottom: 40px;
}

.l-margin-bottom-l {
  margin-bottom: 80px;
}

.l-margin-bottom-b {
  margin-bottom: 160px;
}

.l-grid {
  display: flex;
  align-content: stretch;
  flex-wrap: wrap;
  justify-content: space-around;
}

.l-item-list .grid-item {
  flex: 1 1;
  flex-basis: 44%;
  margin: 0 10px 10px;
}

.l-item-list-column {
  flex-direction: column;
  flex-wrap: nowrap;
}

@media screen and (min-width: 1000px) {
  .l-main {
    width: 1000px;
    margin: 0 auto;
  }
  .l-feature-content,
  .l-pickup-content {
    display: flex;
  }
  .l-pickup-content {
    justify-content: space-between;
    padding: 20px;
  }
  .l-margin-bottom {
    margin-bottom: 40px;
  }
  .l-grid {
    justify-content: flex-start;
  }
  .l-item-list .grid-item {
    flex: 0 auto;
    flex-basis: 235px;
    margin: 0 20px 20px 0;
  }
  .l-item-list .grid-item:nth-child(4n) {
    margin: 0 0 20px;
  }
  .l-item-list-column {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

.header,
.main,
.footer {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
}

.hl3 {
  font-family: "Hiragino Mincho ProN", "Yu Mincho", serif;
  font-weight: 600;
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.33333;
}

.section-title {
  font-weight: 600;
  display: block;
  width: 100%;
  padding: 0 10px;
  text-align: center;
}

.section-title::after {
  display: block;
  width: 50%;
  margin: 0 auto;
  padding: 20px 0 0;
  content: '';
  border-bottom: 1px solid #dedfe0;
}

.section-title-text {
  font-weight: 600;
  text-align: center;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 2;
}

.img-container,
.banner-full,
.banner-list-item {
  display: block;
}

a.linkBtn {
  position: relative;
  width: 84%;
  margin: 0 auto;
  padding: 10px 40px 10px 20px;
  color: white;
  border-bottom: solid 4px #bf737c;
  border-radius: 4px;
  background-color: #ea8c97;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.23077;
}

a.linkBtn:active {
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
  border-bottom: solid 0 #ea8c97;
}

a.linkBtn::after {
  position: absolute;
  top: calc(50% - 10px);
  right: 10px;
  display: block;
  width: 20px;
  height: 20px;
  content: '';
  vertical-align: middle;
  background-image: url(../images/ui_arrow_right@3x.png);
  background-repeat: no-repeat;
  background-size: contain;
}

a.linkBtn-sp {
  font-weight: 600;
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
}

a.list-link-item:active {
  opacity: .8;
}

.link-primary::after {
  position: absolute;
  top: calc(50% - 6px);
  right: 10px;
  display: block;
  width: 12px;
  height: 12px;
  content: '';
  transform: rotate(45deg);
  border-top: solid 2px #2d2d2e;
  border-right: solid 2px #2d2d2e;
}

.front a.linkBtn {
  display: block;
  width: 90%;
  margin: 0 auto 10px;
  font-weight: 600;
  border-bottom: 4px solid #493e32;
  background-color: #675747;
}

.page-mens a.linkBtn {
  border-bottom: solid 4px #007892;
  background-color: #00a2c5;
}

.content-pc {
  display: none;
}

.content-sp {
  display: block;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.header {
  position: relative;
  border-bottom: 3px solid #c1ab95;
}

.logo {
  display: inline-block;
  margin: 10px;
}

.logo-img {
  width: 75px;
}

.menu-button {
  position: fixed;
  z-index: 99999;
  top: 10px;
  right: 10px;
  width: 46px;
  height: 46px;
  padding: 10px;
  background: transparent url(../images/menu_btn.png) center top no-repeat;
  background-size: cover;
  transition: all 0.3s ease-out;
}

.main-menu {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: -100vw;
  visibility: hidden;
  overflow-y: auto;
  width: 65vw;
  height: 100vh;
  padding: 0 10px 0;
  opacity: 0;
  background-color: white;
  transition: all 0.3s ease-out;
}

.menu-bg {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100vw;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  background-color: #2d2d2e;
  transition: all 0.3s ease-out;
}

.nav-open .menu-bg {
  visibility: visible;
  opacity: .4;
}

.nav-open .main-menu {
  right: 0;
  visibility: visible;
  opacity: 1;
}

.nav-open .menu-button {
  right: calc(65vw + 8px);
  left: auto;
}

.hero {
  height: 580px;
  padding: 20px 20px 80px;
}

.hero-text {
  font-weight: 600;
  position: relative;
  top: 300px;
  padding: 5px 30px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.875;
}

.front .hero {
  background: url(../images/bg_hero_s.jpg) center top no-repeat;
  background-size: cover;
}

.front .hero-text {
  top: 266px;
  padding: 5px 15px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 2.14286;
}

.page-uvcut .hero {
  background: url(../images/bg_hero_s_1.jpg) center top no-repeat;
  background-size: cover;
}

.page-uvcut .hero-text {
  top: 178px;
}

.page-cool .hero {
  background: url(../images/bg_hero_s_2.jpg) center top no-repeat;
  background-size: cover;
}

.page-cool .hero-text {
  top: 242px;
}

.page-rain .hero {
  background: url(../images/bg_hero_s_3.jpg) center top no-repeat;
  background-size: cover;
}

.page-rain .hero-text {
  top: 232px;
}

.page-chief-umbrella .hero {
  background: url(../images/bg_hero_s_4.jpg) center top no-repeat;
  background-size: cover;
}

.page-folding-umbrella .hero {
  background: url(../images/bg_hero_s_5.jpg) center top no-repeat;
  background-size: cover;
}

.page-folding-umbrella .hero-text {
  top: 272px;
  padding: 5px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 2.14286;
}

.page-small-size .hero {
  background: url(../images/bg_hero_s_6.jpg) center top no-repeat;
  background-size: cover;
}

.page-small-size .hero-text {
  top: 246px;
  padding: 5px 10px;
}

.page-large-size .hero {
  height: 552px;
  background: url(../images/bg_hero_s_7.jpg) center top no-repeat;
  background-size: cover;
}

.page-large-size .hero-text {
  top: 228px;
  padding: 5px 10px;
}

.page-weight .hero {
  background: url(../images/bg_hero_s_8.jpg) center top no-repeat;
  background-size: cover;
}

.page-weight .hero-text {
  top: 272px;
  padding: 5px 10px;
}

.page-color .hero {
  background: url(../images/bg_hero_s_9.jpg) center top no-repeat;
  background-size: cover;
}

.page-color .hero-text {
  top: 226px;
  padding: 5px 10px;
}

.page-business .hero {
  background: url(../images/bg_hero_s_10.jpg) center top no-repeat;
  background-size: cover;
}

.page-business .hero-text {
  top: 286px;
  padding: 5px 10px;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 2;
}

.page-casual .hero {
  background: url(../images/bg_hero_s_11.jpg) center -96px no-repeat;
  background-size: cover;
}

.page-casual .hero-title {
  position: relative;
  top: 36px;
}

.page-casual .hero-text {
  top: 318px;
  padding: 5px 10px;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 2;
}

.page-ladies .hero {
  background: url(../images/bg_hero_s_12.jpg) center top no-repeat;
  background-size: cover;
}

.page-ladies .hero-title {
  position: relative;
  top: 16px;
}

.page-ladies .hero-text {
  top: 278px;
  padding: 5px 10px;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 2;
}

.page-sport .hero {
  background: url(../images/bg_hero_s_13.jpg) center top no-repeat;
  background-size: cover;
}

.page-sport .hero-title {
  position: relative;
  top: 16px;
}

.page-sport .hero-text {
  top: 276px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.875;
}

.page-wasou .hero {
  background: url(../images/bg_hero_s_14.jpg) center top no-repeat;
  background-size: cover;
}

.page-wasou .hero-text {
  top: 262px;
}

.page-gift1 .hero {
  height: 453px;
  background: url(../images/bg_hero_s_15.jpg) center top no-repeat;
  background-size: cover;
}

.page-gift1 .hero-text {
  top: 160px;
  padding: 5px 15px;
}

.page-gift2 .hero {
  height: 453px;
  background: url(../images/bg_hero_s_16.jpg) center top no-repeat;
  background-size: cover;
}

.page-gift2 .hero-text {
  top: 136px;
  padding: 5px 15px;
}

.page-gift3 .hero {
  height: 529px;
  background: url(../images/bg_hero_s_17.jpg) center top no-repeat;
  background-size: cover;
}

.page-gift3 .hero-text {
  top: 240px;
  padding: 5px 15px;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 2;
}

.page-mens .hero {
  height: 600px;
  background: url(../images/bg_hero_s_18.jpg) center top no-repeat;
  background-size: cover;
}

.page-mens .hero-text {
  top: 288px;
  padding: 5px 15px;
  color: white;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 2.14286;
}

.feature {
  padding: 20px 10px;
  background-image: linear-gradient(-45deg, #ded0b6 25%, #daccae 25%, #daccae 50%, #ded0b6 50%, #ded0b6 75%, #daccae 75%, #daccae);
  background-size: 5px 5px;
}

.feature-content-item {
  width: 100%;
  margin: 0 auto 40px;
  padding: 15px 0 0;
}

.feature-content-item p {
  padding: 0 20px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.71429;
}

.feature-content-item span {
  font-weight: 600;
  color: #ea4b61;
}

.feature-content-item .emphasis {
  color: #00a2c5;
}

.feature-content-item .content-title {
  margin: 0 20px 0 auto;
}

.page-mens .feature-content-item span {
  color: #881024;
}

.content-title {
  width: auto;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.feature-content-1 {
  background: url(../images/bg_feature_s_1_1.png) left top no-repeat;
  background-size: cover;
}

.feature-content-1 .content-title {
  width: 42%;
}

.feature-content-2 {
  margin-bottom: 0;
  background: url(../images/bg_feature_s_1_2.png) left top no-repeat;
  background-size: cover;
}

.feature-content-2 .content-title {
  width: 60%;
}

.front .feature {
  background-image: none;
}

.front .feature-content-item {
  height: auto;
  margin: 0 auto 40px;
  padding: 15px 0 20px;
  background: #faf5ee url(../images/bg_qaa_s.png) center top no-repeat;
  background-size: 100%;
  border-radius: 4px;
}

.front .feature-content-1 .content-title {
  width: 66%;
}

.front .feature-content-2 .content-title {
  width: 60%;
}

.front .feature-content-3 .content-title {
  width: 62%;
}

.page-cool .feature-content-1 {
  background: url(../images/bg_feature_s_2_1.png) left top no-repeat;
  background-size: cover;
}

.page-cool .feature-content-1 .content-title {
  width: 50%;
}

.page-cool .feature-content-2 {
  background: url(../images/bg_feature_s_2_2.png) left top no-repeat;
  background-size: cover;
}

.page-cool .feature-content-2 .content-title {
  width: 52%;
}

.page-rain .feature-content-1 {
  background: url(../images/bg_feature_s_3_1.png) left top no-repeat;
  background-size: cover;
}

.page-rain .feature-content-1 .content-title {
  width: 50%;
}

.page-rain .feature-content-2 {
  background: url(../images/bg_feature_s_3_2.png) left top no-repeat;
  background-size: cover;
}

.page-rain .feature-content-2 .content-title {
  width: 62%;
}

.page-chief-umbrella .feature-content-1,
.page-folding-umbrella .feature-content-1 {
  background: url(../images/bg_feature_s_4_1.png) left top no-repeat;
  background-size: cover;
}

.page-chief-umbrella .feature-content-1 .content-title,
.page-folding-umbrella .feature-content-1 .content-title {
  width: 56%;
}

.page-chief-umbrella .feature-content-2,
.page-folding-umbrella .feature-content-2 {
  background: url(../images/bg_feature_s_4_2.png) left top no-repeat;
  background-size: cover;
}

.page-chief-umbrella .feature-content-2 .content-title,
.page-folding-umbrella .feature-content-2 .content-title {
  width: 60%;
}

.page-small-size .feature-content-1,
.page-large-size .feature-content-1 {
  background: url(../images/bg_feature_s_6_1.png) left top no-repeat;
  background-size: cover;
}

.page-small-size .feature-content-1 .content-title,
.page-large-size .feature-content-1 .content-title {
  width: 60%;
}

.page-small-size .feature-content-2,
.page-large-size .feature-content-2 {
  background: url(../images/bg_feature_s_6_2.png) left top no-repeat;
  background-size: cover;
}

.page-weight .feature-content-1 {
  height: 526px;
  background: url(../images/bg_feature_s_8_1.png) left top no-repeat;
  background-size: cover;
}

.page-weight .feature-content-1 .content-title {
  width: 54%;
  margin-bottom: 160px;
}

.page-weight .feature-content-2 {
  background: url(../images/bg_feature_s_8_2.png) left top no-repeat;
  background-size: cover;
}

.page-color .feature-content-1 {
  background: url(../images/bg_feature_s_9_1.png) left top no-repeat;
  background-size: cover;
}

.page-color .feature-content-1 .content-title {
  width: 60%;
}

.page-color .feature-content-2 {
  background: url(../images/bg_feature_s_9_2.png) left top no-repeat;
  background-size: cover;
}

.page-business .feature-content-1 {
  background: url(../images/bg_feature_s_10_1.png) left top no-repeat;
  background-size: cover;
}

.page-business .feature-content-1 .content-title {
  width: 62%;
}

.page-business .feature-content-2 {
  background: url(../images/bg_feature_s_10_2.png) left top no-repeat;
  background-size: cover;
}

.page-business .feature-content-2 .content-title {
  width: 54%;
}

.page-casual .feature-content-1 {
  height: 454px;
  background: url(../images/bg_feature_s_11_1.png) left top no-repeat;
  background-size: contain;
}

.page-casual .feature-content-1 .content-title {
  width: 60%;
  margin-bottom: 160px;
}

.page-casual .feature-content-2 {
  background: url(../images/bg_feature_s_11_2.png) left top no-repeat;
  background-size: cover;
}

.page-casual .feature-content-2 .content-title {
  width: 56%;
}

.page-ladies .feature-content-1 {
  background: url(../images/bg_feature_s_12_1.png) left top no-repeat;
  background-size: cover;
}

.page-ladies .feature-content-1 .content-title {
  width: 60%;
}

.page-ladies .feature-content-2 {
  background: url(../images/bg_feature_s_12_2.png) left top no-repeat;
  background-size: cover;
}

.page-sport .feature-content-1 {
  background: url(../images/bg_feature_s_13_1.png) left top no-repeat;
  background-size: cover;
}

.page-sport .feature-content-1 .content-title {
  width: 60%;
}

.page-sport .feature-content-2 {
  background: url(../images/bg_feature_s_13_2.png) left top no-repeat;
  background-size: cover;
}

.page-wasou .feature-content-1 {
  background: url(../images/bg_feature_s_14_1.png) left top no-repeat;
  background-size: cover;
}

.page-wasou .feature-content-1 .content-title {
  width: 60%;
}

.page-wasou .feature-content-2 {
  background: url(../images/bg_feature_s_14_2.png) left top no-repeat;
  background-size: cover;
}

.page-gift1 .feature-content-1 {
  background: url(../images/bg_feature_s_15_1.png) left top no-repeat;
  background-size: cover;
}

.page-gift1 .feature-content-1 .content-title {
  width: 58%;
}

.page-gift1 .feature-content-2 {
  background: url(../images/bg_feature_s_15_2.png) left top no-repeat;
  background-size: cover;
}

.page-gift2 .feature-content-1 {
  background: url(../images/bg_feature_s_16_1.png) left top no-repeat;
  background-size: cover;
}

.page-gift2 .feature-content-1 .content-title {
  width: 52%;
}

.page-gift2 .feature-content-2 {
  background: url(../images/bg_feature_s_16_2.png) left top no-repeat;
  background-size: cover;
}

.page-gift3 .feature-content-1 {
  background: url(../images/bg_feature_s_17_1.png) left top no-repeat;
  background-size: cover;
}

.page-gift3 .feature-content-1 .content-title {
  width: 60%;
}

.page-gift3 .feature-content-2 {
  background: url(../images/bg_feature_s_17_2.png) left top no-repeat;
  background-size: cover;
}

.page-mens .feature {
  background-image: none;
  background-color: #e9f8ff;
  border: 2px solid #90cbe9;
}

.page-mens .feature-content-3 {
  height: auto;
  padding-top: 0;
  background: none;
}

.feature-content-3 {
  height: 240px;
  margin: 0 auto;
  padding-top: 80px;
  background: url(../images/bg_feature_s_1_3.png) left top no-repeat;
  background-size: cover;
}

.pickup-1,
.pickup-2 {
  position: relative;
  padding-bottom: 40px;
}

.pickup-point-img {
  display: none;
}

.pickup-colFirst,
.pickup-colSecond {
  position: relative;
}

.pickup-title {
  text-align: center;
}

.pickup-title-img {
  width: 62.5%;
  margin: 0 auto;
}

.pickup-title-text {
  margin-bottom: 20px;
  padding: 0 10px;
  text-align: center;
  font-size: 11px;
  font-size: 1.1rem;
  line-height: 1.63636;
}

.pickup-photo,
.pickup-voice {
  margin: 0 auto 20px;
  padding: 0 20px;
}

.pickup-voice {
  position: relative;
  display: flex;
  width: calc(100vw - 40px);
  padding: 28px 10px 10px;
  border-radius: 4px;
  background-color: white;
}

.pickup-voice::after {
  position: absolute;
  top: -28px;
  left: 0;
  display: block;
  width: 50%;
  height: 48px;
  content: '';
  vertical-align: middle;
  background-image: url(../images/pickup_voice_title@3x.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.pickup-voice::before {
  position: relative;
  top: 0;
  display: block;
  flex-basis: 60px;
  margin-right: 10px;
  content: '';
  vertical-align: middle;
  background-image: url(../images/pickup_voice_user@3x.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.pickup-voice-text {
  font-family: "M PLUS Rounded 1c", sans-serif;
  width: 100%;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.84615;
}

a.item-list-link {
  display: block;
  color: #2d2d2e;
  border: none;
}

.item-list-data {
  font-weight: 600;
  padding: 10px;
  color: white;
  background-color: #ea8c97;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.84615;
}

.menu-list {
  display: block;
  margin: 0;
  padding: 0 10px;
}

.menu-list li {
  list-style: none;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.84615;
}

a.menu-link {
  position: relative;
  display: block;
  padding: 5px 20px 5px 0;
  border-bottom: 1px solid #dedfe0;
}

a.menu-link.link-primary::after {
  right: 5px;
  border-color: #dedfe0;
}

.menu-title {
  position: relative;
  font-weight: 600;
  margin: 0;
  padding: 10px 0;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.menu-title::after {
  position: absolute;
  top: 12px;
  right: 5px;
  display: block;
  width: 12px;
  height: 12px;
  content: '';
  transform: rotate(135deg);
  border-top: solid 2px #2d2d2e;
  border-right: solid 2px #2d2d2e;
  transition: all 0.3s ease-out;
}

.menu-title.menu-title-top::after {
  display: none;
}

.menu-title.open {
  color: #ea4b61;
}

.menu-title.open::after {
  transform: rotate(-45deg);
  border-top: solid 2px #dedfe0;
  border-right: solid 2px #dedfe0;
}

.menu-item {
  display: none;
  margin: 0;
  opacity: 0;
  transition: all 0.3s ease-out;
}

.menu-item ul {
  padding: 0;
}

.menu-item.open {
  display: block;
  padding: 0 0 10px;
  animation: fadeIn 1s forwards;
}

.footer-menu-list .menu-title {
  margin-bottom: 10px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1;
}

.footer-menu-list .menu-item {
  padding: 10px 0;
}

.footer {
  padding-bottom: 20px;
}

.btn-pnav {
  position: relative;
  display: block;
  padding: 10px 20px;
  background-color: #f7f8fa;
}

.btn-pnav::after {
  position: absolute;
  top: calc(50% - 6px);
  right: 20px;
  display: block;
  width: 12px;
  height: 12px;
  content: '';
  transform: rotate(-45deg);
  border-top: solid 2px #2d2d2e;
  border-right: solid 2px #2d2d2e;
}

.copyright {
  display: block;
  text-align: center;
}

@media screen and (min-width: 1000px) {
  .content-pc {
    display: block;
  }
  .content-sp {
    display: none;
  }
  .front a.linkBtn {
    width: 80%;
    text-align: center;
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.33333;
  }
  a.list-link-item:hover {
    opacity: .8;
  }
  .l-header {
    display: flex;
    flex-direction: column-reverse;
    border-bottom: 0;
  }
  .logo {
    width: 1000px;
    margin: 10px auto;
    display: flex;
    align-items: center;
  }
  .header-subTitle {
    margin-left: 10px;
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 1.84615;
    font-weight: 600;
  }
  .logo-img {
    width: 134px;
  }
  .breadcrumb {
    padding: 20px 0;
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 1;
  }
  .hero {
    height: 360px;
    padding: 20px;
  }
  .hero-text {
    font-family: "Hiragino Mincho ProN", "Yu Mincho", serif;
    font-weight: 600;
    top: 10px;
    width: 380px;
    padding: 25px 30px;
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 2.30769;
  }
  .hero-title {
    margin-bottom: 0;
  }
  .hero-title .content-title {
    margin: 0 0 0 10px;
  }
  .front .hero {
    background: url(../images/bg_hero.jpg) center top no-repeat;
    background-size: contain;
  }
  .front .hero-text {
    top: 20px;
    padding: 0px 20px;
  }
  .page-uvcut .hero {
    background: url(../images/bg_hero_1.jpg) center top no-repeat;
    background-size: contain;
  }
  .page-uvcut .hero-text {
    top: 0;
  }
  .page-cool .hero {
    background: url(../images/bg_hero_2.jpg) center top no-repeat;
    background-size: contain;
  }
  .page-cool .hero-text {
    top: 10px;
  }
  .page-rain .hero {
    background: url(../images/bg_hero_3.jpg) center top no-repeat;
    background-size: cover;
  }
  .page-rain .hero-text {
    top: 10px;
  }
  .page-chief-umbrella .hero {
    background: url(../images/bg_hero_4.jpg) center top no-repeat;
    background-size: cover;
  }
  .page-chief-umbrella .hero-text {
    top: 5px;
  }
  .page-folding-umbrella .hero {
    background: url(../images/bg_hero_5.jpg) center top no-repeat;
    background-size: cover;
  }
  .page-folding-umbrella .hero-text {
    top: 10px;
    padding: 25px 30px;
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 2.30769;
  }
  .page-small-size .hero {
    background: url(../images/bg_hero_6.jpg) center top no-repeat;
    background-size: cover;
  }
  .page-small-size .hero-text {
    top: 10px;
    padding: 25px 30px;
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 2.30769;
  }
  .page-large-size .hero {
    height: 360px;
    background: url(../images/bg_hero_7.jpg) center top no-repeat;
    background-size: contain;
  }
  .page-large-size .hero-text {
    top: 10px;
    padding: 25px 30px;
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 2.30769;
  }
  .page-weight .hero {
    background: url(../images/bg_hero_8.jpg) center top no-repeat;
    background-size: contain;
  }
  .page-weight .hero-text {
    top: 26px;
    padding: 5px 20px;
  }
  .page-color .hero {
    background: url(../images/bg_hero_9.jpg) center top no-repeat;
    background-size: contain;
  }
  .page-color .hero-text {
    top: 32px;
    padding: 5px 20px;
  }
  .page-business .hero {
    background: url(../images/bg_hero_10.jpg) center top no-repeat;
    background-size: contain;
  }
  .page-business .hero-text {
    top: 96px;
    left: 56px;
    padding: 5px 20px;
  }
  .page-casual .hero {
    background: url(../images/bg_hero_11.jpg) center top no-repeat;
    background-size: contain;
  }
  .page-casual .hero-text {
    top: 96px;
    left: 26px;
    padding: 5px 20px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2.14286;
  }
  .page-ladies .hero {
    background: url(../images/bg_hero_12.jpg) center top no-repeat;
    background-size: contain;
  }
  .page-ladies .hero-text {
    top: 28px;
    left: 25px;
    padding: 5px 20px;
  }
  .page-sport .hero {
    background: url(../images/bg_hero_13.jpg) center top no-repeat;
    background-size: contain;
  }
  .page-sport .hero-title {
    top: 0;
    left: -24px;
  }
  .page-sport .hero-text {
    top: 18px;
    padding: 5px 20px;
  }
  .page-wasou .hero {
    background: url(../images/bg_hero_14.jpg) center top no-repeat;
    background-size: contain;
  }
  .page-wasou .hero-title {
    top: -8px;
    left: -8px;
  }
  .page-wasou .hero-text {
    top: 10px;
    padding: 5px 20px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2.14286;
  }
  .page-gift1 .hero {
    height: 360px;
    background: url(../images/bg_hero_15.jpg) center top no-repeat;
    background-size: contain;
  }
  .page-gift1 .hero-text {
    top: 26px;
    padding: 5px 15px 5px 30px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2.14286;
  }
  .page-gift2 .hero {
    height: 360px;
    background: url(../images/bg_hero_16.jpg) center top no-repeat;
    background-size: contain;
  }
  .page-gift2 .hero-title {
    position: relative;
    top: 90px;
  }
  .page-gift2 .hero-text {
    top: 108px;
    padding: 5px 15px 5px 30px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2.14286;
  }
  .page-gift3 .hero {
    height: 360px;
    background: url(../images/bg_hero_17.jpg) center top no-repeat;
    background-size: contain;
  }
  .page-gift3 .hero-text {
    top: 32px;
    padding: 5px 15px 5px 30px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2.14286;
  }
  .page-mens .hero {
    height: 360px;
    background: url(../images/bg_hero_18.jpg) center top no-repeat;
    background-size: contain;
  }
  .page-mens .hero-title {
    position: relative;
    top: 60px;
    left: 30px;
  }
  .page-mens .hero-text {
    top: 80px;
    width: 420px;
    padding: 0;
    font-family: "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2.14286;
  }
  .feature {
    margin-bottom: 80px;
  }
  .feature-content-item {
    width: 470px;
    height: 333px;
    margin: 0 auto;
    padding: 0;
  }
  .feature-content-item .img-container {
    position: relative;
    padding-bottom: 70px;
  }
  .feature-content-item .content-title {
    position: absolute;
    top: 25px;
    right: 20px;
    width: auto;
    max-width: 100%;
    margin: 0;
  }
  .feature-content-item p {
    padding: 0 20px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.57143;
  }
  .feature-content-1 {
    background: url(../images/bg_feature_1_1.png) center top no-repeat;
    background-size: cover;
  }
  .feature-content-1 p {
    width: 390px;
  }
  .feature-content-2 {
    margin-bottom: 0;
    background: url(../images/bg_feature_1_2.png) center top no-repeat;
    background-size: cover;
  }
  .feature-content-3 {
    width: 960px;
    height: 111px;
    padding-top: 50px;
    background: url(../images/bg_feature_1_3.png) center top no-repeat;
    background-size: cover;
  }
  .front .l-feature-content {
    flex-direction: column;
  }
  .front .feature-content-item {
    width: 100%;
    background: #faf5ee url(../images/bg_qaa.png) center top no-repeat;
    background-size: 100%;
  }
  .front .feature-content-item .img-container {
    padding: 0;
  }
  .front .feature-content-item .content-title {
    top: -7px;
    right: auto;
    left: 20px;
  }
  .front .feature-content-item p {
    width: 100%;
    padding: 20px;
  }
  .front .feature-content-item:last-child {
    margin-bottom: 0;
  }
  .page-cool .feature-content-1 {
    background: url(../images/bg_feature_2_1.png) center top no-repeat;
    background-size: cover;
  }
  .page-cool .feature-content-1 .content-title {
    width: 70%;
  }
  .page-cool .feature-content-2 {
    background: url(../images/bg_feature_2_2.png) center top no-repeat;
    background-size: cover;
  }
  .page-cool .feature-content-2 .content-title {
    width: 65%;
  }
  .page-rain .feature-content-1 {
    background: url(../images/bg_feature_3_1.png) center top no-repeat;
    background-size: cover;
  }
  .page-rain .feature-content-1 .content-title {
    width: 70%;
  }
  .page-rain .feature-content-2 {
    background: url(../images/bg_feature_3_2.png) center top no-repeat;
    background-size: cover;
  }
  .page-rain .feature-content-2 .content-title {
    width: 70%;
  }
  .page-chief-umbrella .feature-content-1,
  .page-folding-umbrella .feature-content-1 {
    background: url(../images/bg_feature_4_1.png) center top no-repeat;
    background-size: cover;
  }
  .page-chief-umbrella .feature-content-1 .content-title,
  .page-folding-umbrella .feature-content-1 .content-title {
    width: 70%;
  }
  .page-chief-umbrella .feature-content-2,
  .page-folding-umbrella .feature-content-2 {
    background: url(../images/bg_feature_4_2.png) center top no-repeat;
    background-size: cover;
  }
  .page-chief-umbrella .feature-content-2 .content-title,
  .page-folding-umbrella .feature-content-2 .content-title {
    width: 70%;
  }
  .page-chief-umbrella .feature-content-2 p,
  .page-folding-umbrella .feature-content-2 p {
    width: 420px;
  }
  .page-small-size .feature-content-1,
  .page-large-size .feature-content-1 {
    background: url(../images/bg_feature_6_1.png) center top no-repeat;
    background-size: cover;
  }
  .page-small-size .feature-content-1 .content-title,
  .page-large-size .feature-content-1 .content-title {
    width: 70%;
  }
  .page-small-size .feature-content-1 p,
  .page-large-size .feature-content-1 p {
    width: 360px;
  }
  .page-small-size .feature-content-2,
  .page-large-size .feature-content-2 {
    background: url(../images/bg_feature_6_2.png) center top no-repeat;
    background-size: cover;
  }
  .page-small-size .feature-content-2 .content-title,
  .page-large-size .feature-content-2 .content-title {
    width: 70%;
  }
  .page-small-size .feature-content-2 p,
  .page-large-size .feature-content-2 p {
    width: 360px;
  }
  .page-weight .feature-content-1 {
    height: 333px;
    background: url(../images/bg_feature_8_1.png) center top no-repeat;
    background-size: cover;
  }
  .page-weight .feature-content-1 .content-title {
    width: 70%;
  }
  .page-weight .feature-content-1 p {
    width: 370px;
  }
  .page-weight .feature-content-2 {
    background: url(../images/bg_feature_8_2.png) center top no-repeat;
    background-size: cover;
  }
  .page-weight .feature-content-2 .content-title {
    width: 70%;
  }
  .page-color .feature-content-1 .content-title,
  .page-color .feature-content-2 .content-title {
    width: 70%;
  }
  .page-color .feature-content-1 p,
  .page-color .feature-content-2 p {
    width: 370px;
  }
  .page-color .feature-content-1 {
    height: 333px;
    background: url(../images/bg_feature_9_1.png) center top no-repeat;
    background-size: cover;
  }
  .page-color .feature-content-2 {
    background: url(../images/bg_feature_9_2.png) center top no-repeat;
    background-size: cover;
  }
  .page-business .feature-content-1 .content-title,
  .page-business .feature-content-2 .content-title {
    width: 70%;
  }
  .page-business .feature-content-1 p,
  .page-business .feature-content-2 p {
    width: 370px;
  }
  .page-business .feature-content-1 {
    height: 333px;
    background: url(../images/bg_feature_10_1.png) center top no-repeat;
    background-size: cover;
  }
  .page-business .feature-content-2 {
    background: url(../images/bg_feature_10_2.png) center top no-repeat;
    background-size: cover;
  }
  .page-casual .feature-content-1 .content-title,
  .page-casual .feature-content-2 .content-title {
    width: 70%;
  }
  .page-casual .feature-content-1 p,
  .page-casual .feature-content-2 p {
    width: 370px;
  }
  .page-casual .feature-content-1 {
    height: 333px;
    background: url(../images/bg_feature_11_1.png) center top no-repeat;
    background-size: cover;
  }
  .page-casual .feature-content-2 {
    background: url(../images/bg_feature_11_2.png) center top no-repeat;
    background-size: cover;
  }
  .page-ladies .feature-content-1 .content-title,
  .page-ladies .feature-content-2 .content-title {
    width: 70%;
  }
  .page-ladies .feature-content-1 p,
  .page-ladies .feature-content-2 p {
    width: 370px;
  }
  .page-ladies .feature-content-1 {
    height: 333px;
    background: url(../images/bg_feature_12_1.png) center top no-repeat;
    background-size: cover;
  }
  .page-ladies .feature-content-2 {
    background: url(../images/bg_feature_12_2.png) center top no-repeat;
    background-size: cover;
  }
  .page-sport .feature-content-1 .content-title,
  .page-sport .feature-content-2 .content-title {
    width: 70%;
  }
  .page-sport .feature-content-1 p,
  .page-sport .feature-content-2 p {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 1.84615;
  }
  .page-sport .feature-content-1 {
    height: 333px;
    background: url(../images/bg_feature_13_1.png) center top no-repeat;
    background-size: cover;
  }
  .page-sport .feature-content-1 p {
    width: auto;
  }
  .page-sport .feature-content-2 {
    background: url(../images/bg_feature_13_2.png) center top no-repeat;
    background-size: cover;
  }
  .page-sport .feature-content-2 p {
    width: 370px;
  }
  .page-wasou .feature-content-1 p,
  .page-wasou .feature-content-2 p {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 1.84615;
  }
  .page-wasou .feature-content-1 {
    height: 333px;
    background: url(../images/bg_feature_14_1.png) center top no-repeat;
    background-size: cover;
  }
  .page-wasou .feature-content-1 .content-title {
    width: 70%;
  }
  .page-wasou .feature-content-1 p {
    width: auto;
  }
  .page-wasou .feature-content-2 {
    background: url(../images/bg_feature_14_2.png) center top no-repeat;
    background-size: cover;
  }
  .page-wasou .feature-content-2 .content-title {
    width: 68%;
  }
  .page-wasou .feature-content-2 p {
    width: 370px;
  }
  .page-gift1 .feature-content-1 {
    height: 333px;
    background: url(../images/bg_feature_15_1.png) center top no-repeat;
    background-size: cover;
  }
  .page-gift1 .feature-content-1 .content-title {
    width: 70%;
  }
  .page-gift1 .feature-content-1 p {
    width: auto;
  }
  .page-gift1 .feature-content-2 {
    background: url(../images/bg_feature_15_2.png) center top no-repeat;
    background-size: cover;
  }
  .page-gift1 .feature-content-2 .content-title {
    width: 68%;
  }
  .page-gift1 .feature-content-2 p {
    width: 370px;
  }
  .page-gift2 .feature-content-1 {
    height: 333px;
    background: url(../images/bg_feature_16_1.png) center top no-repeat;
    background-size: cover;
  }
  .page-gift2 .feature-content-1 .content-title {
    width: 70%;
  }
  .page-gift2 .feature-content-1 p {
    width: auto;
  }
  .page-gift2 .feature-content-2 {
    background: url(../images/bg_feature_16_2.png) center top no-repeat;
    background-size: cover;
  }
  .page-gift2 .feature-content-2 .content-title {
    width: 68%;
  }
  .page-gift2 .feature-content-2 p {
    width: 370px;
  }
  .page-gift3 .feature-content-1 {
    height: 333px;
    background: url(../images/bg_feature_17_1.png) center top no-repeat;
    background-size: cover;
  }
  .page-gift3 .feature-content-1 .content-title {
    width: 70%;
  }
  .page-gift3 .feature-content-1 p {
    width: auto;
  }
  .page-gift3 .feature-content-2 {
    background: url(../images/bg_feature_17_2.png) center top no-repeat;
    background-size: cover;
  }
  .page-gift3 .feature-content-2 .content-title {
    width: 70%;
  }
  .page-mens .feature-content-3 {
    background: url(../images/bg_feature_18_1.png) right center no-repeat;
    background-size: contain;
  }
  .page-mens .feature-content-3 .content-title {
    width: 70%;
  }
  .page-mens .feature-content-3 p {
    width: 660px;
  }
  .pickup {
    margin-bottom: 80px;
  }
  .pickup-1,
  .pickup-2 {
    padding: 20px;
  }
  .pickup-colFirst,
  .pickup-colSecond {
    margin: 0;
  }
  .pickup-colFirst {
    width: 440px;
  }
  .pickup-colSecond {
    width: 500px;
  }
  .pickup-photo {
    padding: 0;
  }
  .pickup-voice {
    width: 100%;
  }
  a.linkBtn-pc {
    font-weight: 600;
    padding-right: 40px;
    text-align: right;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 2;
  }
  a.linkBtn-pc:hover {
    background-color: #f2b7be;
    border-color: #ea8c97;
  }
  .itemList {
    margin-bottom: 80px;
  }
  .item-list-capt {
    font-weight: 600;
    overflow: hidden;
    height: 9rem;
    padding: 10px 0;
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 1.84615;
  }
  .item-list-type {
    display: table;
    padding: 5px;
    color: #2d2d2e;
    border-radius: 2px;
    background-color: white;
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 1;
  }
  .main-menu {
    position: relative;
    z-index: 9999;
    top: 0;
    right: auto;
    left: 0;
    visibility: visible;
    overflow-y: visible;
    width: 100%;
    height: auto;
    padding: 0 10px 0;
    opacity: 1;
    background-color: #2d2d2e;
  }
  .main-menu-list.menu-list {
    display: flex;
    justify-content: space-between;
    width: 1000px;
    margin: 0 auto;
    color: white;
  }
  .main-menu-list a.menu-title-link, .main-menu-list a.menu-link {
    font-weight: 600;
    color: white;
  }
  .main-menu-list a.menu-link {
    padding: 10px;
    border: none;
  }
  .main-menu-list a.menu-link:hover {
    background-color: #bf737c;
  }
  .main-menu-list a.menu-link::after {
    display: none;
  }
  .main-menu-list .menu-title {
    position: relative;
    transition: all 0.3s ease-out;
  }
  .main-menu-list .menu-title::after {
    display: none;
  }
  .main-menu-list .menu-title:hover {
    color: #ea4b61;
  }
  .main-menu-list .menu-item.open {
    display: inline-block;
    overflow: hidden;
    position: absolute;
    top: 4.3rem;
    left: calc(50% - 160px);
    width: 320px;
    padding: 0;
    background-color: #ea8c97;
  }
  .footer-menu {
    margin-bottom: 80px;
  }
  .footer-menu a.menu-link {
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1;
    font-weight: normal;
  }
  .footer-menu a.menu-link:hover {
    border-bottom-color: #2d2d2e;
  }
  .footer-menu-list.menu-list {
    padding: 0;
  }
  .footer-menu-list .menu-title {
    margin-bottom: 20px;
  }
  .footer-menu-list .menu-item {
    padding: 10px 0;
  }
  .footer a.btn-pnav {
    width: 1000px;
    margin: 0 auto 40px;
    border-radius: 4px;
    background-color: white;
  }
  .footer a.btn-pnav:hover {
    background-color: #f7f8fa;
  }
  .footer-banner,
  .banner-list {
    margin-bottom: 80px;
  }
  .banner-list .l-item-list-column {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .banner-list .grid-item {
    margin-right: 60px;
  }
  .banner-list .grid-item:nth-child(2n) {
    margin-right: 0;
  }
}

.theme-pickup-content {
  background-image: linear-gradient(-45deg, #faf5ee 25%, #f6eee2 25%, #f6eee2 50%, #faf5ee 50%, #faf5ee 75%, #f6eee2 75%, #f6eee2);
  background-size: 5px 5px;
}
